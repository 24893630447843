import React from "react";
import "./pack.scss";
import GoldChip from "../../assets/pack/gold.png";
const PackYellow = ({ item, chooseId, onClick }) => {
  return (
    <div className="item" onClick={() => onClick(item)}>
      <div className="item-pack">
        <div
          className={chooseId === item._id ? "item-block-select" : "item-block"}
        >
          <img src={item.image} alt="#" className="pack-img" />
          <div className="item-content">
            <span>{item.productItemAmount}</span>
            <img src={GoldChip} alt="#" />
          </div>
        </div>
        <p className="price-text">
          {item.amount} {item.currency}
        </p>
      </div>
    </div>
  );
};

export default PackYellow;
