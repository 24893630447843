import React, { useEffect, useState } from "react";
import userInfo from "../../assets/userinfo.png";
import angle from "../../assets/angle.png";
import question from "../../assets/question.png";
import easyWallet from "../../assets/payments/easywallet_logo.webp";
import blockImg from "../../assets/id-money.png";
import { checkUser_req } from "../../api/payment.api";
import { useLocation, useNavigate } from "react-router-dom";

const EasyWallet = ({ openPopup, statePopup }) => {
  function isValidEmail(email) {
    if (!email) {
      return false;
    }
    email = String(email);

    const regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !!email.match(regex);
  }

  function isValidPhone(phone) {
    if (!phone) {
      return false;
    }

    phone = String(phone);

    return !!phone.match(/[0-9]{8,}/g);
  }
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState({
    userId: window.localStorage.getItem("fill_userId") || "",
  });
  const [userError, setUserError] = useState(false);
  const [open, setOpen] = useState(false);
  const [emailPhone, setEmailPhone] = useState(
    window.localStorage.getItem("fill_emailPhone") || ""
  );
  const [checkError, setCheckError] = useState(false);
  const [emailPhoneError, setEmailPhoneError] = useState(false);
  const combo = location?.state?.id;
  const goFirstPage = () => {
    if (combo) {
      navigate("/combo");
    } else {
      navigate("/payments");
    }
  };
  const handleEmailPhoneChange = (evt) => {
    const value = evt?.target?.value;
    setEmailPhone(value);
  };
  const blockOpen = () => {
    setOpen(!open);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleChange = (evt) => {
    const value = evt?.target?.value;

    setState({
      ...state,
      [evt?.target?.name]: value,
    });
  };
  const checkUser = async () => {
    try {
      let _emailPhone = String(emailPhone);

      if (_emailPhone && _emailPhone[0] === "+") {
        _emailPhone = _emailPhone.slice(1);
      }

      if (
        !_emailPhone ||
        (!isValidEmail(_emailPhone) && !isValidPhone(_emailPhone))
      ) {
        return setEmailPhoneError(true);
      }

      setUserError(false);
      setEmailPhoneError(false);

      const checkUserData = await checkUser_req(state, _emailPhone);

      window.localStorage.setItem("fill_userId", state.userId);
      window.localStorage.setItem("fill_emailPhone", _emailPhone);

      if (checkUserData?.data?.userId) {
        navigate("/easy-wallet-payment", {
          state: { user: checkUserData.data, id: combo ? combo : null },
        });
      }
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.data &&
        e.response.data.data.errorCode === 1
      ) {
        if (e.response.data.data.emailPhone === false) {
          return setEmailPhoneError(true);
        } else if (e.response.data.data.userId === false) {
          return setUserError(true);
        }
      }
      setCheckError(true);
    }
  };
  const EasyWalletContent = () => {
    return (
      <div className="you-money">
        <div className="prev-case" onClick={goFirstPage}>
          <img src={angle} alt="#" />
          <span>назад</span>
        </div>
        <div className="you-money-img-block easy-wallet-img">
          <img src={easyWallet} alt="#" />
        </div>
        <div className="you-money-id">
          <span>ВВЕДИТЕ СВОЙ ID</span>
          <div className="input-block">
            <input
              type="text"
              onChange={handleChange}
              name="userId"
              value={state.userId}
              placeholder="USER ID"
              maxLength={9}
              className={`input ${userError ? "input-error" : ""}`}
            />
            <img src={question} alt="#" onClick={openPopup} />
          </div>
          <p onClick={blockOpen}>Что такое ID?</p>
          {open && (
            <div className="block-question">
              <img src={blockImg} alt="#" />
              <span>
                Нажав на Ваше фото в игре можете увидеть Ваш ID номер в игре
              </span>
            </div>
          )}

          <br />
          <div className="input-block">
            <input
              type="text"
              onChange={handleEmailPhoneChange}
              name="phoneEmail"
              value={emailPhone}
              placeholder="Телефон или email"
              className={`input ${emailPhoneError ? "input-error" : ""}`}
            />
          </div>
          <span className="phone-suggest">Пример: +7XXXXXXXXXX</span>

          {userError && (
            <span className="user-error">Данного ID не существует</span>
          )}
          {emailPhoneError && (
            <span className="user-error">
              Неверный формат телефона или email
            </span>
          )}
          {checkError && (
            <span className="user-error">Что то пошло не так</span>
          )}
        </div>
        <div className="goSecondPage" onClick={checkUser}>
          ДАЛЕЕ
        </div>
      </div>
    );
  };
  return (
    <>
      {" "}
      {statePopup && (
        <div className="popup" onClick={openPopup}>
          <div className="popup-info">
            <img src={userInfo} alt="#" />
            <div className="goSecondPage" onClick={openPopup}>
              ЗАКРЫТЬ
            </div>
          </div>
        </div>
      )}
      {EasyWalletContent()}
    </>
  );
};

export default EasyWallet;
