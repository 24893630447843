import React from "react";
import "./tournament-header.scss";
import tourHeader from "../../assets/tour-header-coins.png";
import bazarLogo from "../../assets/bazarlogo.svg";
import { useNavigate } from "react-router-dom";
const TournamentHeader = () => {
  const navigate = useNavigate();
  const goMain = () => {
    navigate("/");
  };
  const buyChip = () => {
    navigate("/payments");
  };
  return (
    <header className="app-header-tournament">
      <div className="container">
        <div className="header-block">
          <img src={bazarLogo} alt="#" className="logo-main" onClick={goMain} />

          <img
            src={tourHeader}
            alt="#"
            className="buy-chip"
            onClick={buyChip}
          />
        </div>
      </div>
    </header>
  );
};
export default TournamentHeader;
