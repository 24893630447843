import React, { useEffect } from "react";
import { MetaTags } from "react-meta-tags";
import "./privacy-policy.scss";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <MetaTags>
        <title>Blot Club RU - самый лучший Блот</title>
        <meta
          id="meta-description"
          name="description"
          content="description  Онлайн игра Blot Club является инновационным решением настольной игры Блот. Можете наслаждаться любимой игрой в любое время и в любом месте! Игра Blot Club доступна через App Store и Google Play."
        />
        <meta
          id="og-title"
          property="og:title"
          content="Онлайн оплата фишек Bazar Blot Club"
        />
        <meta id="og-image" property="og:image" content="path/to/image.jpg" />
      </MetaTags>
      <div className="privacy-policy">
        <div className="privacy-title">
          <span>ПОЛИТИКА</span>
          <p>КОНФИДЕНЦИАЛЬНОСТИ</p>
        </div>
        <div className="privacy-content">
          <span>
            Политика конфиденциальности и защиты персональных данных
            интернет-магазина Formand.ru
          </span>
          <span>
            Мы осуществляем все требуемые организационные и технические меры
            защиты персональных данных клиента от неправомерных действий
            мошеннического характера.
          </span>
          <span>
            Мы гарантируем конфиденциальность и защиту персональной информации
            от третьих лиц. Доступ к личным данным предоставляется только тем
            сервисам и партнерам, которым она необходима для реализации услуг
            магазина (оформления заказа, доставки товара, email-подписки). Все
            эти лица выполняют обязательства по сохранению персональной
            информации клиента и могут подвергаться взысканиям за нарушение
            обязательств, вплоть до увольнения и уголовного преследования.
          </span>
          <span>
            {" "}
            Передача персональных данных третьим лицам также может
            осуществляться в случаях, предусмотренных законодательством
            Российской Федерации, например, при передаче бизнеса. Новый владелец
            при этом берет на себя обязательства по защите полученной
            информации. Также передача данных возможна для пресечения
            мошенничества.
          </span>
          <span>
            {" "}
            При переходе из нашего интернет-магазина на другие источники
            компания не несет ответственности за конфиденциальность
            представленных на этих ресурсах данных.
          </span>
          <span>
            Вся информация, находящаяся на сайте интернет-магазина, является
            собственностью Formand.ru и защищена законодательно. Пользователи
            могут использовать информацию сайта в личных и некоммерческих целях.
            Использование данных в коммерческих целях запрещено. Мы также
            оставляем за собой право на изменение, удаление или другую работу с
            информацией, представленной на сайте, а также на ограничение доступа
            к сайту и не несем ответственности за сведения, представленные
            клиентами на сайте интернет-магазина в общедоступной форме.
          </span>
          <span>
            Компания оставляет за собой право на изменение Политики
            конфиденциальности для дальнейшего совершенствования системы
            безопасности в соответствии с действующим законодательством. Если у
            Вас возникнут вопросы, связанные с нашей Политикой
            конфиденциальности, Вы можете с нами связаться по телефонам +7 (495)
            142-14-00, +7(800) 302-07-14.
          </span>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
