import React, { useEffect, useState } from "react";
import SmileGoodimg from "../../assets/smile-good.png";
import easyWallet from "../../assets/payments/easywallet_logo.webp";

import "./easy-wallet-success.scss";
import { useLocation } from "react-router-dom";
import { easyPayValidate_req } from "../../api/payment.api";

const EasyWalletSuccess = () => {
  const location = useLocation();

  const locationData = location.pathname.slice(17);
  const [error, setError] = useState(null);

  const validateData = async () => {
    try {
      const response = await easyPayValidate_req(locationData);
      setError(null);
    } catch (e) {
      setError(e.response.data);
    }
  };
  useEffect(() => {
    validateData();
  }, []);

  return (
    <div className="success">
      <div className="success-you-money">
        <img src={easyWallet} alt="#" />
      </div>
      <div className="success-content">
        <img src={SmileGoodimg} alt="#" />

        <span className="success-shop">
          {error ? error : "СПАСИБО ЗА ПОКУПКУ!!"}{" "}
        </span>
      </div>
    </div>
  );
};

export default EasyWalletSuccess;
