import React, { useRef } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";

import "./footer.scss";
import google from "../../assets/footer/web_googleplay.svg";
import ios from "../../assets/footer/web_appstore.svg";

import iconPhone from "../../assets/icon-phone.svg";
import iconTelegram from "../../assets/telegram.svg";
import iconWhatsapp from "../../assets/whatsapp.svg";
import iconEmail from "../../assets/icon-email.svg";
import iconAddress from "../../assets/icon-address.svg";
import webApp from "../../assets/web-app.svg";
import footerIcon from "../../assets/footer-icon.svg";
import footerImg from "../../assets/footer/rng_icn.webp";

const Footer = () => {
  const navigate = useNavigate();

  const goPrivacy = () => {
    navigate("/privacy-policy");
  };

  const goPayments = () => {
    navigate("/payments");
  };
  const download = () => {
    navigate("https://blotclub.ru/apk/");
  };

  const newLocal = "icons";
  return (
    <div className="footer">
      <div className="container">
        <div className="down-block">
          <div className="left-block">
            <div className="footer-politics-chips" onClick={goPayments}>
              <img src={footerIcon} alt="#" />
              <span>Купить фишки</span>
            </div>
            <div className="footer-politics" onClick={goPrivacy}>
              <img src={footerIcon} alt="#" />
              <span>Политика конфиденциальности</span>
            </div>
            <span className="footer-name">ИП "ОГАНЯН АРМАН ТАДЕВОСОВИЧ"</span>
            <div className="phone">
              <div className="icon">
                <img src={iconPhone} alt="#" />
              </div>
              <span>+79687497373</span>
            </div>
            <div className="social">
              <div className={newLocal}>
                {/* <a
                  href="https://t.me/BlotClubRu"
                  rel="noopener"
                  target="_blank"
                >
                  <img src={iconViber} alt="#" />
                </a> */}
                <a
                  href="https://t.me/BlotClubRu"
                  rel="noopener"
                  target="_blank"
                >
                  <img src={iconTelegram} alt="#" />
                </a>
                <a
                  href="https://wa.me/37441236090"
                  rel="noopener"
                  target="_blank"
                >
                  <img src={iconWhatsapp} alt="#" />
                </a>
              </div>
              <span>+37441236090</span>
            </div>
            <div className="mail">
              <div className="icon">
                <img src={iconEmail} alt="#" />
              </div>
              <span>info@candywings.com</span>
            </div>
            <div className="location">
              <div className="icon">
                <img src={iconAddress} alt="" />
              </div>
              <span>
                115172, Россия, Москва, улица Народная, дом 13, кв. 183
              </span>
            </div>
          </div>
          <div className="right-block">
            <p className="right-block-title">Скачать приложение</p>
            <div className="store">
              <div className="google">
                <a
                  href="http://onelink.to/bazarblotclub"
                  target="_blank"
                  className="ios"
                  rel="noreferrer"
                >
                  <img src={ios} alt="" />
                </a>
              </div>
              <div className="app">
                <a
                  href="http://onelink.to/bazarblotclub"
                  target="_blank"
                  className="ios"
                  rel="noreferrer"
                >
                  <img src={google} alt="" />
                </a>
              </div>
            </div>
            <a href="https://blotclub.ru/apk/">
              <img src={webApp} className="web-app" alt="#" />
            </a>
            <a
              href="https://blotclub.ru/apk/"
              className="mobile-app"
            >
              {/* <img src={mobileApp} className="mobile-app" alt="#" /> */}
            </a>

            {/* <span></span> */}

            <a
              className="blot-logo"
              href="https://itechlabs.com/certificates/CandyWingsInc/RNG_Certificate_UK_BlotClub_26Apr23.pdf"
              target="_blank"
            >
              <img src={footerImg} alt="#" />
              <p>RNG certified</p>
              {/* <Link to="/payments">
                <img src={footerImg} alt="#" />
              </Link> */}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
