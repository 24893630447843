import { axiosInstanceTournament } from "./config.api";

export const tournamentList_req = async (page) => {
  const response = await axiosInstanceTournament.get(
    `/tournament/list?page=${page}&limit=10`
  );
  return response.data;
};

export const tournamentId_req = async (tournamentId) => {
  const response = await axiosInstanceTournament.get(
    `/tournament/data?tournamentId=${tournamentId}`
  );
  return response.data;
};

export const tournamentUserId_req = async (tournamentId, userId) => {
  const response = await axiosInstanceTournament.get(
    `/tournament/user?tournamentId=${tournamentId}&userId=${userId}`
  );
  return response.data;
};
export const allTournamentUserId_req = async (userId, page) => {
  const response = await axiosInstanceTournament.get(
    `/tournament/list?userId=${userId}&page=${page}&limit=10`
  );
  return response.data;
};
