import React from "react";
import "./header-bottom-block.scss";
import headerBottomLeftImg from "../../../assets/vip2.webp";
import headerBottomCombo from "../../../assets/buy-now-combo.webp";

import headerBottomComboMobile from "../../../assets/buy-now-combo-mobile.webp";
import { useNavigate } from "react-router-dom";
import redChip from "../../../assets/red-chip.png";
import yellow_bg from "../../../assets/gold.png";
import vip_pack from "../../../assets/vip-img.png";
const HeaderBottomBlock = () => {
  const navigate = useNavigate();
  const comboText = [
    { img: redChip, chip: "100.000", chipText: "Красных фишек" },
    { img: yellow_bg, chip: "1000", chipText: "Золотых фишек" },
    { img: vip_pack, chip: "1 месяц", chipText: "VIP" },
  ];
  const goCombo = () => {
    navigate("/combo", { state: { state: "combo" } });
  };
  return (
    <div className="header-bottom-block">
      <div className="container-1">
        <div className="top-block">
          <span className="mega-combo-text-mobile">MEGA комбо</span>
          <div className="header-bottom-left-1">
            <img
              src={headerBottomLeftImg}
              alt="#"
            />
          </div>
          <div className="right-block">
            <div className="mega-combo-text-1">
              <span>MEGA комбо</span>
            </div>
          </div>
        </div>
        <div className="combo-text-block-1">
          {comboText.map((item) => (
            <div className="combo-text-item">
              <img src={item.img} alt="#" />
              <span>{item.chip}</span>
              <p>{item.chipText}</p>
            </div>
          ))}
        </div>
        <div className="combo-img-1" onClick={goCombo}>
          <img src={headerBottomCombo} alt="#" />
        </div>
        <div className="combo-img-1-mobile" onClick={goCombo}>
          <img src={headerBottomComboMobile} alt="#" />
        </div>
      </div>
      <div className="container">
        <div className="header-bottom-left">
          <img
            src={headerBottomLeftImg}
            alt="#"
            className="header-bottom-left-img"
          />
        </div>

        <span className="mega-combo-text">MEGA комбо</span>
        <div className="combo-text-block">
          {comboText.map((item) => (
            <div className="combo-text-item">
              <img src={item.img} alt="#" />
              <span>{item.chip}</span>
              <p>{item.chipText}</p>
            </div>
          ))}
        </div>
        <div className="combo-img" onClick={goCombo}>
          <img src={headerBottomCombo} alt="#" />
        </div>
      </div>
    </div>
  );
};
export default HeaderBottomBlock;
