import React, { useEffect } from "react";
import "./payments.scss";
import "./new-payments.scss";
import paymentsCard from "../../assets/master-visa-card.png";
import tinkoffImg from "../../assets/tinkoff.svg";
import spbImg from "../../assets/spb.svg";

import qiwi from "../../assets/qiwi-new.png";
import idramImg from "../../assets/idram.svg";
import telcellImg from "../../assets/telcell-horizontal.png";
import youMoney from "../../assets/you-money.png";
import newPaymImage from "../../assets/addImage.png";
import easyWallet from "../../assets/payments/easywallet_logo.webp";
import redChip from "../../assets/red-chip-img.png";
import redChipSmall from "../../assets/red-chip.png";
import angle from "../../assets/angle.png";
import { useNavigate } from "react-router-dom";
import { MetaTags } from "react-meta-tags";

const Payments = () => {
  const navigate = useNavigate();
  const goYouMoney = () => {
    navigate("/yoo-money-user");
  };

  const goTinkoff = () => {
    navigate("/tinkoff-user");
  };

  const goIdram = () => {
    navigate("/idram-user");
  };
  const goTellCell = () => {
    navigate("/tel-cell-user");
  };

  const goFirstPage = () => {
    navigate("/");
  };

  const goEasyWallet = () => {
    navigate("/easy-wallet");
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (window.ym) {
      window.ym(88053345, "init", {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
      });
    }
  }, []);
  const goPaymentPage = () => {
    navigate("/payment-page");
  };
  const goMain = () => {};
  return (
    <div className="payments">
      <MetaTags>
        <title>Онлайн оплата фишек Bazar Blot Club</title>
        <meta
          id="meta-description"
          name="description"
          content="Способы оплаты фишек через банковские карты - Visa, Mastercard, МИР, SberPay и через электронные кошельки Qiwi и ЮMoney. Покупаете фишки в Blot Club получаете бонус 30% от покупки!"
        />
        <meta
          id="og-title"
          property="og:title"
          content="Онлайн оплата фишек Bazar Blot Club"
        />
        <meta
          id="og-image"
          property="og:image"
          content="path/to/image.jpg"
        />
      </MetaTags>

      <div className="container">
        <div
          className="prev-case"
          onClick={goFirstPage}
        >
          <img
            src={angle}
            alt="#"
          />
          <span>назад</span>
        </div>
        <div className="payments-title">
          <img
            src={redChip}
            alt="#"
          />
          <div className="payments-title-block">
            <span>КУПИТЬ </span>
            <p>ФИШКИ</p>
          </div>
        </div>
        <div className="payments-block-new"></div>
        <div className="payments-block">
          <div className="payments-content">
            <div className="content-block">
              <div className="payments-content-title">
                <span>ОНЛАЙН ОПЛАТА </span>
                <p>банковскими КАРТАМИ ИЛИ ЭЛЕКТРОННЫМИ ДЕНЬГАМИ</p>
              </div>

              <div className="payments-chip">
                <img
                  src={redChipSmall}
                  alt="#"
                />
                <div className="chips-info">
                  <span>Стоимость красных фишек:</span>
                </div>
              </div>
              <div className="chips-info-text">
                <span>Например:</span>
                <p>250 руб. = 2275 фишек</p>
              </div>
              <i>
                можете приобрести на любую сумму, + если есть умножения, то
                покупка умножается
              </i>

              <div className="payment-card-block">
                <div
                  className="payments-card"
                  onClick={goYouMoney}
                >
                  <img
                    src={youMoney}
                    alt="#"
                    className="you-money"
                  />
                  <div className="you-money-card">
                    <img
                      src={paymentsCard}
                      alt="#"
                    />
                  </div>
                  <div className="payment-buy">
                    <span>ОПЛАТИТЬ</span>
                  </div>
                </div>

                <div
                  className="payments-card "
                  onClick={goTinkoff}
                >
                  <div className="tinkoff-wrapper">
                    <img
                      src={tinkoffImg}
                      alt="#"
                      className="tinkoff"
                    />
                    <img
                      src={spbImg}
                      alt="#"
                      className="spb"
                    />
                  </div>
                  <div className="payment-buy">
                    <span>ОПЛАТИТЬ</span>
                  </div>
                </div>
              </div>
              <div className="new-payment-block">
                <img
                  src={newPaymImage}
                  className="new-pay-img"
                />
                <p>БОЛЬШЕ ПЛАТЕЖЕЙ ТУТ</p>
                <div
                  className="payment-new-buy"
                  onClick={goPaymentPage}
                >
                  <span>ОПЛАТИТЬ</span>
                </div>
              </div>
              <div className="payment-card-block">
                {/* <a
                  href="https://qiwi.com/payment/form/37676"
                  className="payments-card"
                >
                  <img src={qiwi} alt="#" className="you-money-qiwi" />
                  <div className="payment-buy">
                    <span>ОПЛАТИТЬ</span>
                  </div>
                </a> */}
                <div
                  className="payments-card"
                  onClick={goTellCell}
                >
                  <img
                    src={telcellImg}
                    alt="#"
                    className="idram-img tellcell-img"
                  />
                  <div className="payment-buy">
                    <span>ОПЛАТИТЬ</span>
                  </div>
                </div>
                <div
                  className="payments-card"
                  onClick={goIdram}
                >
                  <img
                    src={idramImg}
                    alt="#"
                    className="idram-img"
                  />
                  <div className="payment-buy">
                    <span>ОПЛАТИТЬ</span>
                  </div>
                </div>
              </div>
              <div className="payment-card-block">
                {/* <div
                  className="payments-card"
                  onClick={goTellCell}
                >
                  <img
                    src={telcellImg}
                    alt="#"
                    className="idram-img tellcell-img"
                  />
                  <div className="payment-buy">
                    <span>ОПЛАТИТЬ</span>
                  </div>
                </div> */}
                <div
                  className="payments-card"
                  onClick={goEasyWallet}
                >
                  <img
                    src={easyWallet}
                    alt="#"
                    className="idram-img easy-img"
                  />
                  <div className="payment-buy">
                    <span>ОПЛАТИТЬ</span>
                  </div>
                </div>
                <div className="payments-card">
                  <div className="payment-buy payment-buy-soon">
                    <span>СКОРО</span>
                  </div>
                </div>
                {/* <div
                  className="payments-card"
                  onClick={goEasyWallet}
                >
                  <img
                    src={easyWallet}
                    alt="#"
                    className="idram-img easy-img"
                  />
                  <div className="payment-buy">
                    <span>ОПЛАТИТЬ</span>
                  </div>
                </div> */}
              </div>
              <span className="payments-text">
                Владельцы интернет-кошельков могут оплатить покупку удобным для
                себя способом. Непосредственно после покупки игрок получит
                купленные фишки.
              </span>
            </div>
          </div>
        </div>

        <div className="payments-politics">
          <div className="payment-politics-title">
            <span>ПОЛИТИКА</span>
            <p>ВОЗВРАТОВ ПЛАТЕЖЕЙ</p>
          </div>
          <span className="payment-politics-text">
            Уважаемые Клиенты, информируем Вас о том, что при запросе возврата
            денежных средств при отказе от покупки, возврат производится
            исключительно на ту же банковскую карту, с которой была произведена
            оплата. В случае возникновения вопросов обращайтесь на почту
            support@blotclub.am.
          </span>
        </div>
        <div className="payments-politics">
          <div className="payment-politics-title">
            <span>ПОЛИТИКА</span>
            <p>БЕЗОПАСНОСТИ</p>
          </div>
          <span className="payment-politics-text">
            При оплате заказа банковской картой, обработка платежа (включая ввод
            номера карты) происходит на защищенной странице процессинговой
            системы, которая прошла международную сертификацию. Это значит, что
            Ваши конфиденциальные данные (реквизиты карты, регистрационные
            данные и др.) не поступают в интернет-магазин, их обработка
            полностью защищена и никто, в том числе наш интернет-магазин, не
            может получить персональные и банковские данные клиента. При работе
            с карточными данными применяется стандарт защиты информации,
            разработанный международными платёжными системами Visa и
            Masterсard-Payment Card Industry Data Security Standard (PCI DSS),
            что обеспечивает безопасную обработку реквизитов Банковской карты
            Держателя. Применяемая технология передачи данных гарантирует
            безопасность по сделкам с Банковскими картами путем использования
            протоколов Secure Sockets Layer (SSL), Verifiedby Visa, Secure Code
            и закрытых банковских сетей, имеющих высшую степень защиты.
          </span>
        </div>
      </div>
    </div>
  );
};

export default Payments;
