import React from "react";
import "./pack.scss";
import DoneIcon from "../../assets/pack/done-icon.svg";

const PackVip = ({ item, index, chooseId, onClick }) => {
  console.log("item", item);
  return (
    <div className="item" onClick={() => onClick(item)}>
      <div className="item-pack">
        <div
          className={chooseId === item._id ? "item-block-select" : "item-block"}
        >
          <img
            src={item.image}
            alt="#"
            className="pack-img-vip" //{index === 1 ? "pack-img-vip" : "pack-img-vip"}
          />
          <div className="item-content-text">
            {index === 1 && <span>1 неделя</span>}
            {index === 2 && <span>1 месяц</span>}
            {index === 3 && <span>1 год</span>}
          </div>

          {chooseId === item._id && <img src={DoneIcon} alt="#" />}
        </div>
        <p className="price-text">
          {item.amount} {item.currency}
        </p>
      </div>
    </div>
  );
};

export default PackVip;
