import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useLocation, useNavigate } from "react-router-dom";
import { tournamentList_req } from "../../api/tournament.api";
import redChip from "../../assets/red-chip.png";
import vip_pack from "../../assets/vip-img.png";
import inActive from "../../assets/angle-inactive.png";
import activeIcon from "../../assets/angle-active.png";
import TournamentHeader from "../../components/tournament-header/tournament-header";

import "./tour-list.scss";
import { MetaTags } from "react-meta-tags";

const Tourlist = () => {
  const [tourData, setTourData] = useState(null);
  const [data, setData] = useState({ text: "" });
  const [error, setError] = useState("");
  const [pageCountPagination, setPageCountPagination] = useState(null);
  const [page, setPage] = useState(1);
  const location = useLocation();

  const navigate = useNavigate();
  const tourList = async () => {
    const response = await tournamentList_req(page);
    setPageCountPagination(response.data.pagesCount);
    setTourData(response.data);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    tourList();
  }, [page]);
  const handleClick = (data) => {
    const index = data.selected;
    setPage(index + 1);
    navigate(`/tour-list/${index + 1}`);
  };
  const handleChange = (evt) => {
    const value = evt?.target?.value;

    setData({
      ...data,
      [evt?.target?.name]: value,
    });
  };

  const searchUser = () => {
    if (data.text.length > 0) {
      navigate("/tournament-user/1", {
        state: { state: data.text, path: location.pathname },
      });
    } else {
      setError("введите Ваш ID номер игры");
    }
  };

  const goUserList = (item) => {
    navigate("/user-list", {
      state: { state: item, path: location.pathname, userId: data.text },
    });
  };
  const startDateFn = (item) => {
    const date = new Date(item);
    let year = `${date.getFullYear()}`;

    const yearData = year.slice(2);

    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = ("0" + date.getDate()).slice(-2);
    return `${day}.${month}.${yearData}`;
  };

  return (
    <>
      <MetaTags>
        <title>Blot Club Ru - история турниров</title>
        <meta
          id="meta-description"
          name="description"
          content="Blot Club Ru - история турниров"
        />
        <meta
          id="og-title"
          property="og:title"
          content="Blot Club Ru - история турниров"
        />
        <meta id="og-image" property="og:image" content="path/to/image.jpg" />
      </MetaTags>
      <TournamentHeader />

      <div className="tour-list">
        <div className="container">
          <div className="tour-list-header">
            <p>АРХИВ ТУРНИРОВ</p>
            <div className="right-header">
              <span className="input-top-text">я в турнире</span>
              <div className="search-user">
                <div className="tour-input">
                  <input
                    type={"number"}
                    placeholder="ВАШ ID"
                    onChange={handleChange}
                    value={data.text}
                    name="text"
                    maxLength={10}
                  />
                </div>
                <div onClick={searchUser} className="search-btn">
                  НАЙТИ
                </div>
              </div>

              {error && <span className="error-text-id">{error}</span>}
            </div>
          </div>
        </div>
        <div className="tour-list-content">
          <div className="container">
            <div className="tournament-tour-list">
              <div className="container">
                <table>
                  <thead>
                    <tr>
                      <th>Название</th>
                      <th></th>
                      <th>Призовой фонд</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tourData?.tournaments?.map((item) => (
                      <tr>
                        <td
                          className="tour-name"
                          onClick={() => goUserList(item)}
                        >
                          <span>{item.name[1]}</span>
                          <div className="date-mobile">
                            {startDateFn(item.startDate)} -{" "}
                            {startDateFn(item.endDate)}
                          </div>
                        </td>
                        <td className="tour-date">
                          {startDateFn(item.startDate)} -{" "}
                          {startDateFn(item.endDate)}
                        </td>

                        <td className="red-fund">
                          {item.tournamentFund?.red && (
                            <div>
                              <img src={redChip} alt="#" />
                              <span>{item.tournamentFund.red}</span>
                            </div>
                          )}
                          {item.tournamentFund?.vip && (
                            <div className="red-fund-chip">
                              <img src={vip_pack} alt="#" />
                              <span>Vip</span>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {tourData?.tournaments?.length > 0 && tourData?.pagesCount > 1 && (
            <ReactPaginate
              previousLabel={
                <img
                  src={page === 1 ? inActive : activeIcon}
                  alt="#"
                  className={page > 1 ? "active-rotate" : ""}
                />
              }
              nextLabel={
                <img
                  src={page === pageCountPagination ? inActive : activeIcon}
                  alt="#"
                  className={
                    page === pageCountPagination ? "inactive-rotate" : ""
                  }
                />
              }
              breakLabel={`...`}
              pageCount={pageCountPagination}
              marginPagesDisplayed={1}
              pageRangeDisplayed={3}
              onPageChange={(data) => handleClick(data)}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item prev"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item next"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item breakDots"}
              breakLinkClassName={"page-link"}
              activeClassName={"active-page"}
              forcePage={page - 1}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Tourlist;
