import { axiosInstance, axiosInstanceYupiter } from "./config.api";

export const paymentConfig_req = async () => {
  const response = await axiosInstance.post("/yoo/config");
  return response.data;
};

export const vipConfig_req = async () => {
  const response = await axiosInstance.get("/yoo/vip-config");
  return response.data;
};

export const checkUser_req = async (state, emailPhone) => {
  const userId = state.userId;
  const response = await axiosInstance.post("/yoo/check-user", {
    userId,
    emailPhone,
  });
  return response;
};

// export const checkPayment = async (state) => {
//   const response = await axiosInstance
// }

export const paymentTinkoffConfig_req = async () => {
  const response = await axiosInstance.get("/tinkoff/config");
  return response.data;
};

export const vipConfigTinkoff_req = async () => {
  const response = await axiosInstance.get("/tinkoff/vip-config");
  return response.data;
};

export const checkUserTinkoff_req = async (state, emailPhone) => {
  const userId = state.userId;
  const response = await axiosInstance.post("/tinkoff/check-user", {
    userId,
    emailPhone,
  });
  return response;
};

export const paymentIdramConfig_req = async () => {
  const response = await axiosInstance.get("/idram/config");
  return response.data;
};
export const paymentEasyPayConfig_req = async () => {
  const response = await axiosInstance.get("/easypay/config");
  return response.data;
};
export const paymentTelCellConfig_req = async () => {
  const response = await axiosInstance.get("/telcell/config");
  return response.data;
};

export const vipConfigIdram_req = async () => {
  const response = await axiosInstance.get("/idram/vip-config");
  return response.data;
};

export const vipConfigEasyPay_req = async () => {
  const response = await axiosInstance.get("/easypay/vip-config");
  return response.data;
};
export const vipConfigTelCell_req = async () => {
  const response = await axiosInstance.get("/telcell/vip-config");
  return response.data;
};

export const checkUserIdram_req = async (state, emailPhone) => {
  const userId = state.userId;
  const response = await axiosInstance.post("/idram/check-user", {
    userId,
    emailPhone,
  });
  return response;
};
export const checkUserTellCell_req = async (state, emailPhone) => {
  const userId = state.userId;
  const response = await axiosInstance.post("/telcell/check-user", {
    userId,
    emailPhone,
  });
  return response;
};

export const getIdramData_req = async (
  platform,
  userId,
  dataAmount,
  chipType,
  vipId,
  specialPackId
) => {
  const amount = chipType === 4 ? "35000" : dataAmount;
  // const amount = chipType === 4 ? "10" : dataAmount;
  const params = {
    platform,
    userId,
    amount,
    chipType,
    specialPackId,
  };

  if (vipId) {
    params.vipId = vipId;
  }

  return await axiosInstance.get("/idram/data", {
    params,
  });
};
export const getTellCellData_req = async (
  platform,
  userId,
  dataAmount,
  chipType,
  vipId,
  specialPackId
) => {
  const amount = chipType === 4 ? "35000" : dataAmount;
  // const amount = chipType === 4 ? "10" : dataAmount;
  const params = {
    platform,
    userId,
    amount,
    chipType,
    lang: "en",
    specialPackId: specialPackId === "combo" ? 100 : undefined,
  };

  if (vipId) {
    params.vipId = vipId;
  }

  return await axiosInstance.get("/telcell/data", {
    params,
  });
};

export const getEasyData_req = async (
  platform,
  userId,
  dataAmount,
  chipType,
  vipId,
  specialPackId
) => {
  const amount = chipType === 4 ? "35000" : dataAmount;
  // const amount = chipType === 4 ? "10" : dataAmount;
  const params = {
    platform,
    userId,
    amount,
    chipType,
    specialPackId,
  };

  if (vipId) {
    params.vipId = vipId;
  }

  return await axiosInstance.get("/easypay/create", {
    params,
  });
};

export const easyPayValidate_req = async (orderId) => {
  const response = await axiosInstance.post("/easypay/validate", {
    orderId,
  });
  return response;
};

export const newPayment_req = async (state, productId) => {
  const userId = state.userId;
  const email = state.email;
  const response = await axiosInstanceYupiter.post("/xsolla/create-payment", {
    userId,
    email,
    productId,
  });
  return response;
};

export const getNewProducts_req = async () => {
  const response = await axiosInstanceYupiter.get("/xsolla/products");
  return response?.data;
};
export const getUserId = async (userId) => {
  const response = await axiosInstanceYupiter.get(`/user?userId=${userId}`);
  return response?.data;
};
