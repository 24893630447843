import React from "react";
import "./pack.scss";
import RedChip from "../../assets/pack/red_chip.svg";
import DoneIcon from "../../assets/pack/done-icon.svg";
const Pack = ({ item, chooseId, onClick }) => {
  return (
    <div className="item" onClick={() => onClick(item)}>
      <div className="item-pack">
        <div
          className={chooseId === item._id ? "item-block-select" : "item-block"}
        >
          <img src={item.image} alt="#" className="pack-img" />
          <div className="item-content">
            <span>{item.productItemAmount}</span>
            <img src={RedChip} alt="#" />
          </div>
          {chooseId === item._id && <img src={DoneIcon} />}
        </div>
        <p className="price-text">
          {item.amount} {item.currency}
        </p>
      </div>
    </div>
  );
};

export default Pack;
