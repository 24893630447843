import React from "react";
import "./header.scss";
import headerPhone from "../../assets/header/header-phone.png";
import bazarLogo from "../../assets/bazarlogo.svg";

import boyNowMobile from "../../assets/buy-now-mobile.webp";
import man from "../../assets/man.webp";
import apple from "../../assets/apple.svg";

import google from "../../assets/google.svg";
import burgerMenu from "../../assets/burger-menu.svg";
import closeIcon from "../../assets/X.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HeaderBottomBlock from "./header-bottom-block/header-bottom-block";

const Header = ({
  scrollRef,
  scrollHeader,
  openBurger,
  setOpenBurger,
  changeBurger,
}) => {
  const location = useLocation();

  const navigate = useNavigate();

  const locationSlice = location.pathname.slice(1);

  const locationPath = locationSlice?.length < 2 ? true : false;

  const goPayments = () => {
    navigate("/payments");
  };
  const goMain = () => {
    navigate("/");
  };

  const goTourList = () => {
    navigate("/tour-list/1");
  };
  return (
    <>
      <header
        className={locationPath ? "app-header-main" : "app-header"}
        ref={scrollRef}
      >
        <div className={scrollHeader ? "header-block-scroll" : "header-block"}>
          <div className="content">
            <img
              src={bazarLogo}
              alt="#"
              className={locationPath ? "logo" : "logo-main"}
              onClick={goMain}
            />
            {/* {locationSlice !== "tournament" && (
              <div>
                <img
                  src={tourHeader}
                  alt="#"
                  className="tour-icon"
                  onClick={goTourList}
                />
                <img
                  src={tourHistory}
                  alt="#"
                  className="tour-icon-mobile"
                  onClick={goTourList}
                />
              </div>
            )} */}
            <div className="right-block-header">
              <Link to={"/tour-list/1"}>
                <span>ИСТОРИЯ ТУРНИРОВ</span>
              </Link>

              <Link to="/payments">
                <div className="payment-money-header">
                  <span>КУПИТЬ ФИШКИ</span>
                </div>
              </Link>

              {/* <span>ИГРАТЬ</span> */}
            </div>
            <div className="right-block-mobile" onClick={changeBurger}>
              {openBurger ? (
                <img src={closeIcon} alt="#" className="close-img" />
              ) : (
                <img src={burgerMenu} alt="#" className="burger-img" />
              )}
            </div>
          </div>
        </div>
        <div className="container">
          {/* <div className="open-burger"></div> */}
          {locationSlice !== "tournament" && (
            <>
              {!locationPath ? (
                <img src={headerPhone} alt="#" className="header-phone" />
              ) : (
                <>
                  <div className="section">
                    <div className="content-block">
                      <span>ЛУЧШИЙ</span>
                      <p>БЛОТ НА ПЛАНЕТЕ</p>
                      {/* <div className="store-buy-now" onClick={goPayments}>
                        <img src={storeBuyNow} alt="#" />
                      </div> */}
                    </div>
                    <div className="content-block-img">
                      <img src={man} alt="#" className="man" />
                      {/* <img src={headerMain} alt="#" className="header-main" /> */}
                    </div>

                    <div className="store-market">
                      <img src={apple} alt="#" className="store-market-apple" />
                      <div className="border-mobile"></div>
                      <img
                        src={google}
                        alt="#"
                        className="store-market-google"
                      />
                    </div>
                  </div>
                  <div className="section-mobile">
                    <div className="content-header-mobile">
                      <div className="text-mobile-left">
                        <span>ЛУЧШИЙ</span>
                        <p>
                          БЛОТ НА <br /> ПЛАНЕТЕ
                        </p>
                      </div>
                      <img src={man} alt="#"  />
                    </div>
                    <div className="store-buy-now-header" onClick={goPayments}>
                      <img src={boyNowMobile} alt="#" />
                    </div>
                    <div className="store-market-mobile">
                      <img src={apple} alt="#" className="store-market-apple" />
                      <div className="border-mobile"></div>
                      <img
                        src={google}
                        alt="#"
                        className="store-market-google"
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
        {locationPath && <HeaderBottomBlock />}
      </header>
    </>
  );
};
export default Header;
