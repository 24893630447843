import React from "react";
import "./error-page.scss";
import errorIcon from "../../assets/error-icon.png";
import { useNavigate } from "react-router-dom";
import angle from "../../assets/angle.png";
import { MetaTags } from "react-meta-tags";

const ErrorPage = () => {
  const navigate = useNavigate();
  const goFirstPage = () => {
    navigate("/");
  };
  return (
    <>
      <MetaTags>
        <title>Blot Club RU - самый лучший Блот</title>
        <meta
          id="meta-description"
          name="description"
          content="description  Онлайн игра Blot Club является инновационным решением настольной игры Блот. Можете наслаждаться любимой игрой в любое время и в любом месте! Игра Blot Club доступна через App Store и Google Play."
        />
        <meta
          id="og-title"
          property="og:title"
          content="Онлайн оплата фишек Bazar Blot Club"
        />
        <meta id="og-image" property="og:image" content="path/to/image.jpg" />
      </MetaTags>
      <div className="error-page">
        <div className="prev-case" onClick={goFirstPage}>
          <img src={angle} alt="#" />
          <span>назад</span>
        </div>
        <div className="error-page-content">
          <img src={errorIcon} alt="#" />
          <div className="error-content">
            <span>404</span>
            <p>Ежки-матрешки, этой страницы нет</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
