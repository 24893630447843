import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "../idram/idram.scss";
import "./easy-wallet-payment.scss";

import easyWallet from "../../assets/payments/easywallet_logo.webp";
import redChip from "../../assets/red-chip.png";
import sale from "../../assets/sale-50.png";
import redCombo from "../../assets/red-chip-combo.png";
import yellowCombo from "../../assets/yellow-combo.png";
import vipCombo from "../../assets/vip-combo.png";
import userInfo from "../../assets/userinfo.png";

import angle from "../../assets/angle.png";
import {
  getEasyData_req,
  paymentEasyPayConfig_req,
  vipConfigEasyPay_req,
} from "../../api/payment.api";

import { MetaTags } from "react-meta-tags";
import yellow_bg from "../../assets/gold.png";
import vip_pack from "../../assets/vip-pack.png";

import { isMobile } from "react-device-detect";

const EasyWalletPayment = ({ openPopup, statePopup }) => {
  const navigate = useNavigate();

  const location = useLocation();

  const [stateSum, setStateSum] = useState("");
  const [configState, setConfigState] = useState(null);
  const [configVip, setConfigVip] = useState(null);
  const [sumState, setSumState] = useState("");

  const [vipItem, setVipItem] = useState(null);

  const [chipState, setChipState] = useState(1);

  const [vipCheck, setVipCheck] = useState(0);
  const [openVip, setOpenVip] = useState(false);
  const userId = location?.state?.user?.userId;
  const username = location?.state?.user?.username;
  const comboText = [
    { text: "100 000", img: redCombo },
    { text: "1000", img: yellowCombo },
    { text: "1 МЕСЯЦ VIP", img: vipCombo },
  ];
  const comboData = location?.state?.id;

  const goFirstPage = () => {
    navigate("/easy-wallet", {
      state: { id: comboData ? comboData : null },
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const openVipSelect = () => {
    setOpenVip(!openVip);
  };

  const buyChip = async () => {
    try {
      let chipType = chipState === 0 ? 1 : 0;
      if (vipCheck) {
        chipType = 3;
      }
      if (comboData === "combo") {
        chipType = 4;
      }

      const response = await getEasyData_req(
        isMobile ? "mobile" : "web",
        userId,
        vipItem ? vipItem.price : stateSum,
        chipType,
        vipCheck,
        comboData === "combo" ? 100 : undefined
      );

      if (isMobile && response?.data?.url) {
        window.location.href = response.data.url;

        if (response?.data?.webPaymentUrl) {
          setTimeout(() => {
            window.location.href = response.data.url;
            // window.open(response.data.webPaymentUrl, "_blank");
          }, 1500);
        }
      } else if (!isMobile && response?.data?.url) {
        window.location.href = response.data.url;
        // window.open(response.data.webPaymentUrl, "_blank");
      }
    } catch (e) {
      console.error("buyChip Error -> ", e);
    }
  };

  useEffect(() => {
    if (configState && stateSum) {
      handleChangeSum(stateSum);
    }
  }, [chipState]);
  const changeChip = (index) => {
    setChipState(index);
  };

  const getChipsCount = (value, chipType) => {
    let multiplier = 5;

    if (configState) {
      multiplier =
        chipType === "red"
          ? configState.defaultPrice
          : configState.defaultPriceYellow;

      if (configState.ranges && configState.ranges.length) {
        const neededRange =
          chipType === "red"
            ? configState.ranges
                .filter((rangeData) => value >= rangeData.max)
                .sort((a, b) => b.max - a.max)[0]
            : configState.ranges
                .filter((rangeData) => value >= rangeData.maxYellow)
                .sort((a, b) => b.maxYellow - a.maxYellow)[0];

        if (neededRange) {
          multiplier =
            chipType === "red" ? neededRange.price : neededRange.priceYellow;
        }
      }
    }

    let chipsCount = multiplier * value;
    if (chipState === 1) {
      chipsCount += (chipsCount * configState?.percent) / 100;
    } else {
      chipsCount += (chipsCount * configState?.percentYellow) / 100;
    }

    return chipsCount;
  };

  const handleChangeSum = (value) => {
    const chipsCount = getChipsCount(value, chipState === 1 ? "red" : "yellow");
    setSumState(Math.ceil(chipsCount));

    setStateSum(value);
  };

  const configVipReq = async () => {
    const vipData = await vipConfigEasyPay_req();
    setConfigVip(vipData);
  };

  const configDataReq = async () => {
    const configData = await paymentEasyPayConfig_req();

    setConfigState(configData);
  };

  useEffect(() => {
    configDataReq();
    configVipReq();
  }, []);

  const setVipIdData = (item) => {
    setOpenVip(!openVip);
    setVipItem(item);
    setVipCheck(item.vipId);
  };
  const configStateVip = configVip ? Object.values(configVip) : null;

  const renderByStep = () => {
    if (comboData === "combo") {
      return (
        <div className="you-money-second">
          <div className="prev-case" onClick={() => goFirstPage()}>
            <img src={angle} alt="#" />
            <span>назад</span>
          </div>
          <div className="you-money-content">
            <div className="you-money-left">
              <div className="you-money-img-block easy-wallet-img">
                <img src={easyWallet} alt="#" />
              </div>
              <div className="you-money-user">
                <span>ID:</span>
                <p>#{userId}</p>
              </div>
              <div className="you-money-user">
                <span>ИГРОК:</span>
                <p>{username}</p>
              </div>
            </div>
          </div>
          <div className="you-money-input">
            <div className="you-money-id">
              <div className="input-block input-block-combo">
                <div className="combo-info">
                  <span>35.000 драм</span>
                </div>
              </div>
            </div>
          </div>

          <div className="combo-block">
            {comboText.map((item) => (
              <div className="combo-item">
                <span>{item.text}</span>
                <img src={item.img} alt="#" />
              </div>
            ))}
          </div>

          <div
            className={`${comboData === "combo" ? "comboBtn" : "goSecondPage"}`}
            onClick={buyChip}
          >
            ДАЛЕЕ
          </div>
        </div>
      );
    } else {
      return (
        <div className="you-money-second">
          <div className="prev-case" onClick={() => goFirstPage()}>
            <img src={angle} alt="#" />
            <span>назад</span>
          </div>
          <div className="you-money-content">
            <div className="you-money-left">
              <div className="you-money-img-block easy-wallet-img">
                <img src={easyWallet} alt="#" />
              </div>
              <div className="you-money-user">
                <span>ID:</span>
                <p>#{userId}</p>
              </div>
              <div className="you-money-user">
                <span>ИГРОК:</span>
                <p>{username}</p>
              </div>
              <div className="chip-block">
                <div className="gold-chip" onClick={() => changeChip(1)}>
                  <div className="border-block">
                    {chipState === 1 && <div className="block-check"></div>}
                  </div>
                  <img src={redChip} alt="#" />
                  <span className={`${chipState === 1 ? "bold-text" : ""}`}>
                    Красные
                  </span>
                </div>
                <div className="gold-chip" onClick={() => changeChip(0)}>
                  <div className="border-block">
                    {chipState === 0 && <div className="block-check"></div>}
                  </div>
                  <img src={yellow_bg} alt="#" />
                  <span className={`${chipState === 0 ? "bold-text" : ""}`}>
                    Золотые
                  </span>
                </div>
                <div className="gold-chip" onClick={() => changeChip(2)}>
                  <div className="border-block">
                    {chipState === 2 && <div className="block-check"></div>}
                  </div>
                  <img src={yellow_bg} alt="#" />
                  <span className={`${chipState === 2 ? "bold-text" : ""}`}>
                    VIP
                  </span>
                </div>
              </div>
              {/* <div className="you-money-user">
                <span>СУММА:</span>
              </div> */}
            </div>
            <div className="you-money-right">
              <img src={sale} alt="#" />
            </div>
          </div>

          <div className="you-money-input">
            <div className="you-money-id">
              <div className="input-block">
                {chipState === 2 ? (
                  <>
                    {" "}
                    <div className="input-vip" onClick={openVipSelect}>
                      {vipItem ? (
                        <span>
                          {vipItem.name} {vipItem.price} ДРАМ
                        </span>
                      ) : (
                        <span>Выбери пакет</span>
                      )}

                      <img src={vip_pack} alt="#" />
                    </div>
                    {openVip && (
                      <div className="vip-select-block">
                        <ul>
                          {configStateVip?.map((item) => (
                            <li
                              key={item._id}
                              onClick={() => setVipIdData(item)}
                            >
                              <div className="vip-border-block">
                                {vipCheck === item.vipId && (
                                  <div className="vip-center-block"></div>
                                )}
                              </div>
                              <span>{item.name}</span> <p>{item.price} ДР.</p>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </>
                ) : (
                  <input
                    type="text"
                    onChange={(e) => handleChangeSum(e.target.value)}
                    name="sum"
                    value={stateSum}
                    maxLength={9}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                )}

                {/* <span>rub</span> */}
              </div>
            </div>
            {chipState !== 2 && (
              <div className="red-chip-block">
                {sumState && <span>=</span>}

                <span>{sumState}</span>
                {sumState && chipState === 1 && <img src={redChip} alt="#" />}
                {sumState && chipState === 0 && <img src={yellow_bg} alt="#" />}
              </div>
            )}
          </div>
          {chipState !== 2 && (
            <>
              {chipState === 1 ? (
                <span className="you-money-text">
                  250 драм. = {Math.ceil(getChipsCount(250, "red"))} фишек.
                  Минимальная сумма {configState?.minAmount} драм.
                </span>
              ) : (
                <span className="you-money-text">
                  230 драм. = {Math.ceil(getChipsCount(230, "yellow"))} фишек.
                  Минимальная сумма {configState?.minAmountYellow} драм.
                </span>
              )}
            </>
          )}

          <div className="goSecondPage" onClick={buyChip}>
            ДАЛЕЕ
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <MetaTags>
        <title>Оплата через YoоMoney в Blot Club Ru</title>
        <meta
          id="meta-description"
          name="description"
          content="Покупайте фишки в Blot Club и оплачивайте через Юкассу. Приобретение фишек Blot Club в России, через банковские карты mastercard, Visa, МИР, SberPay, а также через QIWI кошелек и YooMoney. Покупайте и получайте бонус от Blot Club в размере 30% от купленных фишек."
        />
        <meta
          id="og-title"
          property="og:title"
          content="Оплата через YoоMoney в Blot Club Ru"
        />
        <meta id="og-image" property="og:image" content="path/to/image.jpg" />
      </MetaTags>
      {statePopup && (
        <div className="popup" onClick={openPopup}>
          <div className="popup-info">
            <img src={userInfo} alt="#" />
            <div className="goSecondPage" onClick={openPopup}>
              ЗАКРЫТЬ
            </div>
          </div>
        </div>
      )}

      {renderByStep()}
    </>
  );
};
export default EasyWalletPayment;
