import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { allTournamentUserId_req } from "../../api/tournament.api";
import TournamentHeader from "../../components/tournament-header/tournament-header";
import goldIcon from "../../assets/medal-gold.png";
import goldCooper from "../../assets/medal-cooper.png";
import goldSilver from "../../assets/medal-silver.png";
import vip_pack from "../../assets/vip-img.png";
import inActive from "../../assets/angle-inactive.png";
import activeIcon from "../../assets/angle-active.png";
import angle from "../../assets/angle.png";
import "./tournament-user.scss";
import redChip from "../../assets/red-chip.png";
import ReactPaginate from "react-paginate";

const TournamentUser = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [state, setState] = useState({
    text: location?.state?.state ? location.state.state : "",
  });
  const [pageCountPagination, setPageCountPagination] = useState(null);
  const [page, setPage] = useState(1);
  const [error, setError] = useState("");
  const [tournamentData, setTournamentData] = useState(null);

  useEffect(() => {}, []);
  const handleChange = (evt) => {
    const value = evt?.target?.value;

    setState({
      ...state,
      [evt?.target?.name]: value,
    });
  };
  const searchUser = async () => {
    try {
      if (state.text.length > 0 && state.text.length < 10) {
        const response = await allTournamentUserId_req(state.text, page);

        setPageCountPagination(response.data.pagesCount);
        if (response.data?.userTournaments?.length === 0) {
          setError("Введенного ID номера нет в турнире");
          setTournamentData(null);
        } else {
          setError(null);
          setTournamentData(response.data);
        }
      }
      if (state.text.length === 0) {
        setError("введите Ваш ID номер игры");
        setTournamentData(null);
      }
      if (state.text.length > 10) {
        setError("Введенного ID номера нет в турнире");
        setTournamentData(null);
      }
    } catch (e) {
      if (e.response.status === 404) {
        setError("Неправильный ID номер");
        setTournamentData(null);
      }
    }
  };
  const handleClick = (data) => {
    const index = data.selected;
    setPage(index + 1);
    navigate(`/tournament-user/${index + 1}`);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    searchUser();
  }, [page]);
  const medalIcon = (item) => {
    if (item === 1) {
      return <img src={goldIcon} alt="#" />;
    }
    if (item === 2) {
      return <img src={goldSilver} alt="#" />;
    }
    if (item === 3) {
      return <img src={goldCooper} alt="#" />;
    }
  };
  const goBack = () => {
    navigate("/tour-list/1");
  };
  const startDateFn = (item) => {
    const date = new Date(item);
    let year = `${date.getFullYear()}`;

    const yearData = year.slice(2);

    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = ("0" + date.getDate()).slice(-2);
    return `${day}.${month}.${yearData}`;
  };
  const goTourPage = (item) => {
    navigate("/user-list", {
      state: { state: item, path: location.pathname, userId: state.text },
    });
  };
  return (
    <>
      <TournamentHeader />
      <div className="container-back-all">
        <div className="back" onClick={goBack}>
          <img src={angle} alt="#" />
          <span>назад</span>
        </div>
      </div>
      <div className="container-tournament">
        <div className="tour-list-header">
          <p>АРХИВ ТУРНИРОВ</p>

          <div className="right-header">
            <span className="input-top-text">я в турнире</span>
            <div className="search-user">
              <div className="tour-input">
                <input
                  type={"number"}
                  placeholder="ВАШ ID"
                  onChange={handleChange}
                  value={state.text}
                  name="text"
                  maxLength={10}
                />
              </div>
              <div onClick={searchUser} className="search-btn">
                НАЙТИ
              </div>
            </div>

            {error && <span className="error-text-id">{error}</span>}
          </div>
        </div>
      </div>
      <div className="all-tournament-user-list">
        <div className="container-tournament">
          {tournamentData?.userTournaments?.map((item) => (
            <>
              <div className="tour-name-block">
                <p className="tour-name" onClick={() => goTourPage(item)}>
                  {item?.tournamentNames?.[1]}
                </p>
                <span>{startDateFn(item.startDate)} - </span>
                <span className="date-item">{startDateFn(item.endDate)}</span>
              </div>
              <div className="tournament-user-list-table">
                <div className="table-head">
                  <div className="th th-place">место</div>
                  <div className="th th-player">игрок</div>
                  <div className="table-flex">
                    <div className="th th-id">ID</div>
                    <div className="th th-point">очки</div>
                    <div className="th th-reward">награда</div>
                  </div>
                </div>
                <div className="table-body active">
                  <div className="tr table-flex">
                    <div className="td td-place">
                      <span className="title">place</span>
                      <div>
                        {item.position} {medalIcon(item.position)}
                      </div>
                    </div>
                    <div className="table-flex table-flex-1">
                      <div className="table-flex">
                        <div className="td td-player">
                          <span className="title">игрок</span>
                          {item.username}
                        </div>
                        <div className="td td-id">
                          <span className="title">ID</span>
                          {item.userId}
                        </div>
                      </div>
                      <div className="table-flex table-flex-2">
                        <div className="td td-point">
                          <span className="title">очки</span>
                          {item.score}
                        </div>
                        <div className="td td-reward">
                          <span className="title">награда</span>
                          <div>
                            {item?.rewards?.map((item) => (
                              <>
                                {item.type === "red" && (
                                  <div className="red-data chip-data">
                                    <img src={redChip} alt="#" />
                                    <span>{item.value}</span>
                                  </div>
                                )}
                                {item.type === "vip" && (
                                  <div className="red-data">
                                    <img src={vip_pack} alt="#" />
                                    <span>VIP</span>
                                  </div>
                                )}
                              </>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}
          {tournamentData?.userTournaments?.length > 0 &&
            tournamentData?.pagesCount > 1 && (
              <ReactPaginate
                previousLabel={
                  <img
                    src={page === 1 ? inActive : activeIcon}
                    alt="#"
                    className={page > 1 ? "active-rotate" : ""}
                  />
                }
                nextLabel={
                  <img
                    src={page === pageCountPagination ? inActive : activeIcon}
                    alt="#"
                    className={
                      page === pageCountPagination ? "inactive-rotate" : ""
                    }
                  />
                }
                breakLabel={`...`}
                pageCount={pageCountPagination}
                marginPagesDisplayed={1}
                pageRangeDisplayed={3}
                onPageChange={(data) => handleClick(data)}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item prev"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item next"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item breakDots"}
                breakLinkClassName={"page-link"}
                activeClassName={"active-page"}
                forcePage={page - 1}
              />
            )}
        </div>
      </div>
    </>
  );
};

export default TournamentUser;
