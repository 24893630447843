import React from "react";
import idramLogo from "../../assets/idram.svg";
import SmileGoodimg from "../../assets/smile-good.png";
import bazarLogo from "../../assets/bazarlogo.svg";

import "./success.scss";
import { useNavigate } from "react-router-dom";

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const goMain = () => {
    navigate("/");
  };
  return (
    <>
      <header className={"app-header"}>
        <div className={"header-block"}>
          <div className="content">
            <img
              src={bazarLogo}
              alt="#"
              className={"logo-main"}
              onClick={goMain}
            />
          </div>
        </div>
      </header>
      <div className="success">
        <div className="success-content">
          <img src={SmileGoodimg} alt="#" />
          <span className="success-shop">СПАСИБО ЗА ПОКУПКУ!!</span>
        </div>
      </div>
    </>
  );
};

export default PaymentSuccess;
