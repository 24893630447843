import React from "react";
import { useNavigate } from "react-router-dom";
import "./header-privacy.scss";
import bazarLogo from "../../../assets/bazarlogo.svg";
import buyChip from "../../../assets/buy-chips.png";

const HeaderPrivacy = ({ scrollRef }) => {
  const navigate = useNavigate();

  const goPayments = () => {
    navigate("/payments");
  };
  const goMain = () => {
    navigate("/");
  };
  return (
    <header className="app-header-privacy" ref={scrollRef}>
      <div className="container">
        <div className="header-block">
          <img
            src={bazarLogo}
            alt="#"
            className="logo-main-header"
            onClick={goMain}
          />

          <img
            src={buyChip}
            alt="#"
            className="buy-chip-data"
            onClick={goPayments}
          />
        </div>
      </div>
    </header>
  );
};

export default HeaderPrivacy;
