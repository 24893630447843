import React, { useEffect, useState } from "react";
import idramLogo from "../../assets/idram.svg";
import Smileimg from "../../assets/smile.png";

import "./payment-error.scss";

const Success = () => {
  return (
      <div className="success">
        <div className="success-you-money">
          <img src={idramLogo} alt="#" />
        </div>
        <div className="success-content">
          <img src={Smileimg} alt="#" />
          <span className="success-shop">Ой, что-то пошло не так.</span>
        </div>
      </div>
  );
};

export default Success;
