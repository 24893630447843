import React, { useState, useEffect } from "react";
import "./payment-page-xsollai.scss";
import { useLocation, useNavigate } from "react-router-dom";

import userInfo from "../../assets/userinfo.png";
import bazarLogo from "../../assets/bazarlogo.svg";
import redChip from "../../assets/red-chip.png";

import yellow_bg from "../../assets/gold.png";
import Xsolla from "../../assets/xsollai-img.png";
import loader from "../../assets/loader.png";
import { getNewProducts_req, newPayment_req } from "../../api/payment.api";
import { MetaTags } from "react-meta-tags";
import Pack from "../../components/pack/pack";
import PackYellow from "../../components/pack-yellow/pack";
import PackVip from "../../components/pack-vip/pack";

const PaymentPageXsollai = ({ openPopup, statePopup }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);
  const location = useLocation();
  const naviagte = useNavigate();

  const [chipState, setChipState] = useState(1);
  const [packId, setPackId] = useState(null);
  const [redData, setRedData] = useState(null);
  const [yellowData, setYellowData] = useState(null);
  const [vipData, setVipData] = useState(null);
  const [chooseId, setChooseId] = useState(null);
  const userId = location?.state?.user?.userId;
  const username = location?.state?.user?.username;
  const { state } = location?.state;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setOpen(false);
    }, 5000);
  }, []);
  const getPayment = async () => {
    const response = await getNewProducts_req();
    setData(response?.data?.packs);
    const red = response?.data?.packs?.filter(
      (item) => item.packId.slice(0, 17) === "bazarblot_redchip"
    );
    const yellow = response?.data?.packs?.filter(
      (item) => item.packId.slice(0, 18) === "bazarblot_goldchip"
    );
    const vip = response?.data?.packs?.filter(
      (item) => item.packId.slice(0, 8) === "BazarVip"
    );
    setRedData(red);
    setYellowData(yellow);
    setVipData(vip);
  };

  useEffect(() => {
    getPayment();
  }, []);
  const changeChip = (index) => {
    setChipState(index);
    setChooseId(null);
    setPackId(null);
    setLoading(false);
  };
  const chooseItem = (item) => {
    setChooseId(item._id);
    setPackId(item.packId);
    setLoading(false);
  };
  const goMain = () => {
    naviagte("/");
  };
  const buyChip = async () => {
    setLoading(true);
    try {
      const response = await newPayment_req(state, packId);

      if (response?.data) {
        setLoading(false);
        window.location.href = response.data.data.redirectionUrl;
      }
    } catch (e) {
      setLoading(true);
    }
  };
  return (
    <>
      <MetaTags>
        <title>Оплата через YoоMoney в Blot Club Ru</title>
        <meta
          id="meta-description"
          name="description"
          content="Покупайте фишки в Blot Club и оплачивайте через Юкассу. Приобретение фишек Blot Club в России, через банковские карты mastercard, Visa, МИР, SberPay, а также через QIWI кошелек и YooMoney. Покупайте и получайте бонус от Blot Club в размере 30% от купленных фишек."
        />
        <meta
          id="og-title"
          property="og:title"
          content="Оплата через YoоMoney в Blot Club Ru"
        />
        <meta id="og-image" property="og:image" content="path/to/image.jpg" />
      </MetaTags>

      {statePopup && (
        <div className="popup" onClick={openPopup}>
          <div className="popup-info">
            <img src={userInfo} alt="#" />
            <div className="goSecondPage" onClick={openPopup}>
              ЗАКРЫТЬ
            </div>
          </div>
        </div>
      )}
      <header className={"app-header-xsollai"}>
        <div className={"header-block"}>
          <div className="content">
            <img
              src={bazarLogo}
              alt="#"
              className={"logo-main"}
              onClick={goMain}
            />
          </div>
        </div>
      </header>
      <div className="xsollai">
        <div className="xsollai-content">
          <div className="xsollai-left">
            <div className="xsollai-img-block">
              <img src={Xsolla} alt="#" />
            </div>
            <div className="xsolla-block">
              <div className="xsollai-user">
                <span>ID:</span>
                <p>#{userId}</p>
              </div>
              <div className="xsollai-user">
                <span>ИГРОК:</span>
                <p>{username}</p>
              </div>
            </div>
            <div className="chip-block">
              <div className="gold-chip" onClick={() => changeChip(1)}>
                <div className="border-block">
                  {chipState === 1 && <div className="block-check"></div>}
                </div>
                <img src={redChip} alt="#" />
                <span className={`${chipState === 1 ? "bold-text" : ""}`}>
                  Красные
                </span>
              </div>
              <div className="gold-chip" onClick={() => changeChip(0)}>
                <div className="border-block">
                  {chipState === 0 && <div className="block-check"></div>}
                </div>
                <img src={yellow_bg} alt="#" />
                <span className={`${chipState === 0 ? "bold-text" : ""}`}>
                  Золотые
                </span>
              </div>
              <div className="gold-chip" onClick={() => changeChip(2)}>
                <div className="border-block">
                  {chipState === 2 && <div className="block-check"></div>}
                </div>
                <img src={yellow_bg} alt="#" />
                <span className={`${chipState === 2 ? "bold-text" : ""}`}>
                  VIP
                </span>
              </div>
            </div>
            <p className="title-pack">Выберите пакет</p>
            {chipState === 1 && (
              <div className="pack-section">
                {redData?.map((item) => (
                  <Pack item={item} chooseId={chooseId} onClick={chooseItem} />
                ))}
              </div>
            )}
            {chipState === 0 && (
              <div className="pack-section">
                {yellowData?.map((item) => (
                  <PackYellow
                    item={item}
                    chooseId={chooseId}
                    onClick={chooseItem}
                  />
                ))}
              </div>
            )}
            {chipState === 2 && (
              <div className="pack-section">
                {vipData?.map((item, index) => (
                  <PackVip
                    item={item}
                    index={index + 1}
                    chooseId={chooseId}
                    onClick={chooseItem}
                  />
                ))}
              </div>
            )}

            <div
              className={chooseId ? "btn-item-xsolla" : "btn-opacity-xsolla"}
              onClick={buyChip}
            >
              {loading ? (
                <img src={loader} alt="#" className="loader" />
              ) : (
                <span>ДАЛЕЕ</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PaymentPageXsollai;
