import axios from "axios";

const baseURL = "https://vk.blotclub.am/api/payment/";
const baseYupiterURL = "https://vk.blotclub.am/api/v2/payments/";
// const baseURL = "https://test.blotclub.am/api/payment/";
const tournamentURL = "https://bcru.blotclub.ru/";

export const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 60000,
});

export const axiosInstanceYupiter = axios.create({
  baseURL: baseYupiterURL,
  timeout: 60000,
});
export const axiosInstanceTournament = axios.create({
  baseURL: tournamentURL,
  timeout: 60000,
});

const successResponse = (response) => {
  return response;
};

const errorResponse = (error) => {
  return Promise.reject(error);
};

axiosInstance.interceptors.response.use(successResponse, errorResponse);
axiosInstanceTournament.interceptors.response.use(
  successResponse,
  errorResponse
);
axiosInstanceYupiter.interceptors.response.use(successResponse, errorResponse);
