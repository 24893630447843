import React, { useEffect } from "react";
import "../payments/payments.scss";
import "./combo.scss";
import paymentsCard from "../../assets/master-visa-card.png";
import tinkoffImg from "../../assets/tinkoff.svg";
import spbImg from "../../assets/spb.svg";
import idramImg from "../../assets/idram.svg";
import telcellImg from "../../assets/telcell-horizontal.png";
import youMoney from "../../assets/you-money.png";
import easyWallet from "../../assets/payments/easywallet_logo.webp";
import redCombo from "../../assets/red-chip-combo.png";
import yellowCombo from "../../assets/yellow-combo.png";
import redChip from "../../assets/vip2.webp";
import vipCombo from "../../assets/vip-combo.png";
import angle from "../../assets/angle.png";
import { useNavigate } from "react-router-dom";
import { MetaTags } from "react-meta-tags";

const Combo = () => {
  const navigate = useNavigate();

  const goYouMoney = () => {
    navigate("/yoo-money-user", { state: { id: "combo" } });
  };

  const goTinkoff = () => {
    navigate("/tinkoff-user", { state: { id: "combo" } });
  };

  const goIdram = () => {
    navigate("/idram-user", { state: { id: "combo" } });
  };
  const goEasyWallet = () => {
    navigate("/easy-wallet", { state: { id: "combo" } });
  };
  const goTellCell = () => {
    navigate("/tel-cell-user", { state: { id: "combo" } });
  };

  const goFirstPage = () => {
    navigate("/");
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    if (window.ym) {
      window.ym(88053345, "init", {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
      });
    }
  }, []);

  const comboText = [
    { text: "100 000", img: redCombo },
    { text: "1000", img: yellowCombo },
    { text: "1 МЕСЯЦ VIP", img: vipCombo },
  ];

  return (
    <div className="payments">
      <MetaTags>
        <title>Онлайн оплата фишек Bazar Blot Club</title>
        <meta
          id="meta-description"
          name="description"
          content="Способы оплаты фишек через банковские карты - Visa, Mastercard, МИР, SberPay и через электронные кошельки Qiwi и ЮMoney. Покупаете фишки в Blot Club получаете бонус 30% от покупки!"
        />
        <meta
          id="og-title"
          property="og:title"
          content="Онлайн оплата фишек Bazar Blot Club"
        />
        <meta
          id="og-image"
          property="og:image"
          content="path/to/image.jpg"
        />
      </MetaTags>
      <div className="container">
        <div
          className="prev-case"
          onClick={goFirstPage}
        >
          <img
            src={angle}
            alt="#"
          />
          <span>назад</span>
        </div>
        <div className="payments-title">
          <img
            src={redChip}
            alt="#"
          />
          <div className="payments-title-block-1">
            <span>КУПИТЬ </span>
            <p>МЕГА КОМБО</p>
          </div>
        </div>
        <div className="payments-block">
          <div className="payments-content payments-first">
            <div className="content-block">
              <div className="payments-content-title">
                <span>ОНЛАЙН ОПЛАТА </span>
                <p>КАРТАМИ ИЛИ ЭЛЕКТРОННЫМИ ДЕНЬГАМИ</p>
              </div>
              <div className="combo-block-text">
                <span className="title-text">4 599 руб. или 35 000 драм</span>
                <div className="combo-chips-block">
                  {comboText.map((item) => (
                    <div className="combo-item">
                      <span>{item.text}</span>
                      <img
                        src={item.img}
                        alt="#"
                      />
                    </div>
                  ))}
                </div>
              </div>

              <div className="payment-card-block">
                <div
                  className="payments-card payment-card-you"
                  onClick={goYouMoney}
                >
                  <img
                    src={youMoney}
                    alt="#"
                    className="you-money"
                  />
                  <div className="you-money-card">
                    <img
                      src={paymentsCard}
                      alt="#"
                    />
                  </div>
                  <div className="payment-buy">
                    <span>ОПЛАТИТЬ</span>
                  </div>
                </div>

                <div
                  className="payments-card payment-card-you"
                  onClick={goTinkoff}
                >
                  <div className="tinkoff-wrapper">
                    <img
                      src={tinkoffImg}
                      alt="#"
                      className="tinkoff"
                    />
                    <img
                      src={spbImg}
                      alt="#"
                      className="spb"
                    />
                  </div>
                  <div className="payment-buy">
                    <span>ОПЛАТИТЬ</span>
                  </div>
                </div>
              </div>

              <div className="payment-card-block">
                <div
                  className="payments-card tell-cell"
                  onClick={goTellCell}
                >
                  <img
                    src={telcellImg}
                    alt="#"
                    className="idram-img tellcell-img"
                  />
                  <div className="payment-buy">
                    <span>ОПЛАТИТЬ</span>
                  </div>
                </div>

                <div
                  className="payments-card idram"
                  onClick={goIdram}
                >
                  <img
                    src={idramImg}
                    alt="#"
                    className="idram-img"
                  />
                  <div className="payment-buy">
                    <span>ОПЛАТИТЬ</span>
                  </div>
                </div>
              </div>
              <div className="payment-card-block">
                <div
                  className="payments-card payment-card-you"
                  onClick={goEasyWallet}
                >
                  <img
                    src={easyWallet}
                    alt="#"
                    className="easy-wallet"
                  />

                  <div className="payment-buy">
                    <span>ОПЛАТИТЬ</span>
                  </div>
                </div>
                <div className="payments-card payment-card-you payment-comming-soon">
                  <div className="payment-buy payment-buy-comming-soon">
                    <span>СКОРО</span>
                  </div>
                </div>
              </div>

              <span className="payments-text">
                Владельцы интернет-кошельков могут оплатить покупку удобным для
                себя способом. Непосредственно после покупки игрок получит
                купленные фишки.
              </span>
            </div>
          </div>
        </div>

        <div className="payments-politics">
          <div className="payment-politics-title">
            <span>ПОЛИТИКА</span>
            <p>ВОЗВРАТОВ ПЛАТЕЖЕЙ</p>
          </div>
          <span className="payment-politics-text">
            Уважаемые Клиенты, информируем Вас о том, что при запросе возврата
            денежных средств при отказе от покупки, возврат производится
            исключительно на ту же банковскую карту, с которой была произведена
            оплата. В случае возникновения вопросов обращайтесь на почту
            support@blotclub.am.
          </span>
        </div>
        <div className="payments-politics">
          <div className="payment-politics-title">
            <span>ПОЛИТИКА</span>
            <p>БЕЗОПАСНОСТИ</p>
          </div>
          <span className="payment-politics-text">
            При оплате заказа банковской картой, обработка платежа (включая ввод
            номера карты) происходит на защищенной странице процессинговой
            системы, которая прошла международную сертификацию. Это значит, что
            Ваши конфиденциальные данные (реквизиты карты, регистрационные
            данные и др.) не поступают в интернет-магазин, их обработка
            полностью защищена и никто, в том числе наш интернет-магазин, не
            может получить персональные и банковские данные клиента. При работе
            с карточными данными применяется стандарт защиты информации,
            разработанный международными платёжными системами Visa и
            Masterсard-Payment Card Industry Data Security Standard (PCI DSS),
            что обеспечивает безопасную обработку реквизитов Банковской карты
            Держателя. Применяемая технология передачи данных гарантирует
            безопасность по сделкам с Банковскими картами путем использования
            протоколов Secure Sockets Layer (SSL), Verifiedby Visa, Secure Code
            и закрытых банковских сетей, имеющих высшую степень защиты.
          </span>
        </div>
      </div>
    </div>
  );
};

export default Combo;
