import React from "react";
import "./main.scss";
import { Carousel } from "react-responsive-carousel";
import slider1 from "../../assets/Slider-image-01.webp";
import slider2 from "../../assets/Slider-image-02.webp";
import slider3 from "../../assets/Slider-image-03.webp";
import slider4 from "../../assets/Slider-image-04.webp";
import slider5 from "../../assets/Slider-image-05.webp";
import socialImage from "../../assets/header/social-image.jpg";

import wildberries from "../../assets/4u.am.png";
import cardBlock from "../../assets/bottom-img.webp";
import iconPlayers from "../../assets/icon-players.png";
import iconCountries from "../../assets/icon-countries.png";
import iconDownloads from "../../assets/icon-downloads.png";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { MetaTags } from "react-meta-tags";
const Main = () => {
  const navigate = useNavigate();
  const goPayments = () => {
    navigate("/payments");
  };
  const goPaymentsYoo = () => {
    navigate("/yoo-money");
  };
  const phone = isMobile;

  const contentBlock = [
    {
      id: 1,
      text: "ИГРОКОВ ОНЛАЙН",
      size: "500.000+",
      image: iconPlayers,
    },
    {
      id: 2,
      text: "СТРАН",
      size: "30+",
      image: iconCountries,
    },

    {
      id: 3,
      text: "СКАЧИВАНИЙ",
      size: "1.000.000+",
      image: iconDownloads,
    },
  ];
  return (
    <>
      <MetaTags>
        <title>Blot Club RU - самый лучший Блот</title>
        <meta
          id="meta-description"
          name="description"
          content="description  Онлайн игра Blot Club является инновационным решением настольной игры Блот. Можете наслаждаться любимой игрой в любое время и в любом месте! Игра Blot Club доступна через App Store и Google Play."
        />
        <meta
          id="og-title"
          property="og:title"
          content="Онлайн оплата фишек Bazar Blot Club"
        />
        <meta
          id="og-image"
          name="og:image"
          property="og:image"
          content={socialImage}
        />
      </MetaTags>
      <div className="app-content">
        <div className="container">
          <div className="content-text">
            <span className="title-text">blot club</span>
            <p>
              Blot Club Ru создана для игроков из России, желающих приобрести
              фишки. На этой странице можно приобрести фишки Blot Club в России,
              через банковские карты mastercard,{" "}
              <span onClick={goPaymentsYoo}>Visa,</span>{" "}
              <span onClick={goPaymentsYoo}>МИР,</span>{" "}
              <span onClick={goPaymentsYoo}>SberPay,</span> а также через{" "}
              <a href="https://qiwi.com/payment/form/37676"> QIWI</a> <></>
              кошелёк и <span onClick={goPaymentsYoo}>YooMoney</span>. Еще фишки
              можете приобрести через терминалы. Покупая фишки в Blot Club,
              получаете бонус 30% от покупки. Есть вопросы? Пишите в чат!
            </p>
          </div>
          {phone && (
            <div className="button-text" onClick={goPayments}>
              <span>купить</span>
            </div>
          )}
        </div>
      </div>
      <div>
        <Carousel
          autoPlay={true}
          autoFocus={true}
          infiniteLoop={true}
          interval={3000}
        >
          <div className="slider-first">
            <img src={slider1} alt="#" />
          </div>
          <div className="slider-first">
            <img src={slider2} alt="#" />
          </div>
          <div className="slider-first">
            <img src={slider3} alt="#" />
          </div>
          <div className="slider-first">
            <img src={slider4} alt="#" />
          </div>
          <div className="slider-first">
            <img src={slider5} alt="#" />
          </div>
        </Carousel>
      </div>
      <div className="app-content">
        <div className="content-block-img">
          {contentBlock.map((item) => (
            <div key={item.id} className="content-item-block">
              <div className="item-img-block">
                <img src={item.image} alt="#" />
              </div>

              <span>{item.size}</span>
              <p>{item.text}</p>
            </div>
          ))}
        </div>
        <div className="content-text-bottom">
          <span>
            Сначала появилась блестящая идея перевести игру на онлайн-уровень.
            Blot Club первым дал возможность сыграть в любимую игру в любое
            время и в любом месте. За несколько лет развития и благодаря работе
            четырех сильных отделов; разработка, услуги SEO, дизайн и служба
            поддержки, у нас есть конкурентоспособный, любимый и бесплатный
            продукт на рынке. Кроме приемлемых ставок, ежедневных бонусов,
            множества шансов на получение бесплатных фишек и приятного
            интерфейса Blot Club создал множество нововведений, чтобы сделать
            игру еще более интересной и запоминающейся. Прежде всего, в игре
            есть три разных стола для игроков с разным уровнем в игре, и все
            пользователи могут видеть результаты и уровни других игроков за
            своим столом. Во время любого игрового раунда, за любым столом можно
            получить и отправить подарки любому игроку, чтобы выразить свои
            эмоции и добавить в игру колорит. В игре есть разные достижения, за
            пару хороших игр можно получить новые значки и дополнительные фишки.
            Ежедневные и ежемесячные турниры не только дают вам шанс получить
            больше фишек, но и добавляют азарт в игру.
          </span>
          <span>
            Для особых пользователей Blot Club также предлагает VIP-статус,
            который дает ряд преимуществ в игре, такие исключительные
            возможности, как выражение эмоций с помощью разных смайликов,
            Vip-рамки, возможность выбрать аватарки…
          </span>
          <span>
            Это всего лишь краткое описание классной игры с классными идеями.
            Чтобы узнать больше, просто начните играть.
          </span>
        </div>
      </div>
      <div className="main-cards">
        <div className="main-img">
          <div className="left-main-block">
            <img src={cardBlock} className="card-block" />
          </div>
          <div className="buy-cards-block">
            <div className="wildberries">
              <img src={wildberries} alt="#" />
            </div>
            <p className="cards-text">Blot Club</p>
            <span className="cards-text-second">Premium Set</span>
            <div className="buy-cards">
              <span>Идеальный подарок для любителей блота</span>
            </div>
            <a
              href="https://4u.am/hy/product/bloti-havakacu-blot-club"
              target="_blank"
              rel="noreferrer"
            >
              <div className="buy-now">Купить</div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
export default Main;
