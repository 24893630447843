import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "./tinkoff.scss";
import TinkoffImg from "../../assets/tinkoff.svg";
import redChip from "../../assets/red-chip.png";
import sale from "../../assets/sale.png";
import userInfo from "../../assets/userinfo.png";
import redCombo from "../../assets/red-chip-combo.png";
import yellowCombo from "../../assets/yellow-combo.png";
import vipCombo from "../../assets/vip-combo.png";
import angle from "../../assets/angle.png";
import {
  paymentTinkoffConfig_req,
  vipConfigTinkoff_req,
} from "../../api/payment.api";

import { MetaTags } from "react-meta-tags";
import yellow_bg from "../../assets/gold.png";
import vip_pack from "../../assets/vip-pack.png";

function isValidEmail(email) {
  if (!email) {
    return false;
  }
  email = String(email);

  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !!email.match(regex);
}

const Tinkoff = ({ openPopup, statePopup }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [stateSum, setStateSum] = useState("");
  const [configState, setConfigState] = useState(null);
  const [configVip, setConfigVip] = useState(null);
  const [sumState, setSumState] = useState("");
  const [vipItem, setVipItem] = useState(null);

  const [open, setOpen] = useState(false);
  const [chipState, setChipState] = useState(1);

  const [vipCheck, setVipCheck] = useState(0);
  const [openVip, setOpenVip] = useState(false);

  const emailPhone = window.localStorage.getItem("fill_emailPhone") || "";

  const dataUser = location?.state?.user;

  const userId = location?.state?.user?.userId;
  const username = location?.state?.user?.username;
  const comboText = [
    { text: "100 000", img: redCombo },
    { text: "1000", img: yellowCombo },
    { text: "1 МЕСЯЦ VIP", img: vipCombo },
  ];

  const comboData = location?.state?.id;
  const formRef = useRef();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const goFirstPage = () => {
    navigate("/tinkoff-user", {
      state: { id: comboData ? comboData : null },
    });
  };
  const onIframeEvent = (ev) => {
    if (
      ev &&
      ev.data === "exit" &&
      ev.origin === "https://securepayments.tinkoff.ru"
    ) {
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setOpen(false);
    }, 5000);

    window.addEventListener("message", onIframeEvent);

    return () => {
      window.removeEventListener("message", onIframeEvent);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const openVipSelect = () => {
    setOpenVip(!openVip);
  };
  useEffect(() => {
    if (comboData === "combo") {
      setChipState(3);
    }
  }, []);
  const buyChip = async () => {
    try {
      if (window.pay && dataUser && formRef.current) {
        let order = null;

        if (chipState === 1) {
          order = `BC_${dataUser?.userId}_redchip_${stateSum}_${Date.now()}`;
        } else if (chipState === 0) {
          order = `BC_${dataUser?.userId}_yellowchip_${stateSum}_${Date.now()}`;
        } else if (chipState === 2 && vipCheck) {
          order = `BC_${dataUser?.userId}_vip_${vipCheck}_${Date.now()}`;
        } else if (chipState === 3) {
          order = `BC_${userId}_special_100_${Date.now()}`;
        }

        if (!order) {
          return;
        }

        const amount =
          comboData === "combo" ? "4599" : vipItem ? vipItem.price : stateSum;

        formRef.current.amount.value = amount;
        formRef.current.order.value = order;

        const phoneOrEmail = isValidEmail(emailPhone) ? "email" : "phone";

        const receiptValue = {
          Taxation: "usn_income",
          Items: [
            {
              Name: "Оплата",
              Price: amount + "00",
              Quantity: 1.0,
              Amount: amount + "00",
              PaymentMethod: "full_prepayment",
              PaymentObject: "service",
              Tax: "none",
            },
          ],
        };

        if (phoneOrEmail === "email") {
          receiptValue.Email = emailPhone;
          formRef.current.email.value = emailPhone;
        } else {
          if (emailPhone[0] === "+") {
            receiptValue.Phone = emailPhone.replace("+", "");
            formRef.current.phone.value = emailPhone.replace("+", "");
          } else {
            receiptValue.Phone = emailPhone;
            formRef.current.phone.value = emailPhone;
          }
        }

        formRef.current.receipt.value = JSON.stringify(receiptValue);

        window.pay(formRef.current);
      }
    } catch (e) {
      console.error("buyChip Error -> ", e);
    }
  };

  useEffect(() => {
    if (configState && stateSum) {
      handleChangeSum(stateSum);
    }
  }, [chipState]);
  const changeChip = (index) => {
    setChipState(index);
  };

  const getChipsCount = (value, chipType) => {
    let multiplier = 5;

    if (configState) {
      multiplier =
        chipType === "red"
          ? configState.defaultPrice
          : configState.defaultPriceYellow;

      if (configState.ranges && configState.ranges.length) {
        const neededRange =
          chipType === "red"
            ? configState.ranges
                .filter((rangeData) => value >= rangeData.max)
                .sort((a, b) => b.max - a.max)[0]
            : configState.ranges
                .filter((rangeData) => value >= rangeData.maxYellow)
                .sort((a, b) => b.maxYellow - a.maxYellow)[0];

        if (neededRange) {
          multiplier =
            chipType === "red" ? neededRange.price : neededRange.priceYellow;
        }
      }
    }

    let chipsCount = multiplier * value;
    if (chipState === 1) {
      chipsCount += (chipsCount * configState?.percent) / 100;
    } else {
      chipsCount += (chipsCount * configState?.percentYellow) / 100;
    }

    return chipsCount;
  };

  const handleChangeSum = (value) => {
    let multiplier = 5;

    if (configState) {
      multiplier =
        chipState === 1
          ? configState.defaultPrice
          : configState.defaultPriceYellow;

      if (configState.ranges && configState.ranges.length) {
        const neededRange =
          chipState === 1
            ? configState.ranges
                .filter((rangeData) => value >= rangeData.max)
                .sort((a, b) => b.max - a.max)[0]
            : configState.ranges
                .filter((rangeData) => value >= rangeData.maxYellow)
                .sort((a, b) => b.maxYellow - a.maxYellow)[0];

        if (neededRange) {
          multiplier =
            chipState === 1 ? neededRange.price : neededRange.priceYellow;
        }
      }
    }

    let chipsCount = multiplier * value;
    if (chipState === 1) {
      chipsCount += (chipsCount * configState?.percent) / 100;
    } else {
      chipsCount += (chipsCount * configState?.percentYellow) / 100;
    }

    setSumState(Math.ceil(chipsCount));

    setStateSum(value);
  };
  const configVipReq = async () => {
    const vipData = await vipConfigTinkoff_req();
    setConfigVip(vipData);
  };
  const configDataReq = async () => {
    const configData = await paymentTinkoffConfig_req();

    setConfigState(configData);
  };

  useEffect(() => {
    configDataReq();
    configVipReq();
  }, []);

  const setVipIdData = (item) => {
    setOpenVip(!openVip);
    setVipItem(item);
    setVipCheck(item.vipId);
  };

  const configStateVip = configVip ? Object.values(configVip) : null;

  const renderByStep = () => {
    if (comboData) {
      return (
        <div className="you-money-second">
          <div className="prev-case" onClick={() => goFirstPage()}>
            <img src={angle} alt="#" />
            <span>назад</span>
          </div>
          <div className="you-money-content">
            <div className="you-money-left">
              <div className="you-money-img-block">
                <img src={TinkoffImg} alt="#" />
              </div>
              <div className="you-money-user">
                <span>ID:</span>
                <p>#{userId}</p>
              </div>
              <div className="you-money-user">
                <span>ИГРОК:</span>
                <p>{username}</p>
              </div>
            </div>
          </div>
          <div className="you-money-input">
            <div className="you-money-id">
              <div className="input-block input-block-combo">
                <div className="combo-info">
                  <span>4599 руб</span>
                </div>
              </div>
            </div>
          </div>
          <div className="combo-block">
            {comboText.map((item) => (
              <div className="combo-item">
                <span>{item.text}</span>
                <img src={item.img} alt="#" />
              </div>
            ))}
          </div>

          <div
            className={`${comboData === "combo" ? "comboBtn" : "goSecondPage"}`}
            onClick={buyChip}
          >
            ДАЛЕЕ
          </div>
          <form
            name="TinkoffPayForm"
            onSubmit={() => buyChip}
            ref={formRef}
            style={{ display: "none" }}
          >
            <input
              className="tinkoffPayRow"
              type="hidden"
              name="terminalkey"
              value="1647250267012"
            />
            <input
              className="tinkoffPayRow"
              type="hidden"
              name="frame"
              value="true"
            />
            <input
              className="tinkoffPayRow"
              type="hidden"
              name="language"
              value="ru"
            />
            <input className="tinkoffPayRow" type="hidden" name="email" />
            <input className="tinkoffPayRow" type="hidden" name="phone" />
            <input
              className="tinkoffPayRow"
              type="text"
              placeholder="Сумма заказа"
              name="amount"
              required
            />
            <input
              className="tinkoffPayRow"
              type="text"
              placeholder="Номер заказа"
              name="order"
            />
            <input className="tinkoffPayRow" type="hidden" name="receipt" />
            {/*<input className="tinkoffPayRow" type="submit" value="Оплатить"/>*/}
          </form>
        </div>
      );
    } else {
      return (
        <div className="you-money-second">
          <div className="prev-case" onClick={() => goFirstPage()}>
            <img src={angle} alt="#" />
            <span>назад</span>
          </div>
          <div className="you-money-content">
            <div className="you-money-left">
              <div className="you-money-img-block">
                <img src={TinkoffImg} alt="#" />
              </div>
              <div className="you-money-user">
                <span>ID:</span>
                <p>#{dataUser?.userId}</p>
              </div>
              <div className="you-money-user">
                <span>ИГРОК:</span>
                <p>{dataUser?.username}</p>
              </div>
              <div className="chip-block">
                <div className="gold-chip" onClick={() => changeChip(1)}>
                  <div className="border-block">
                    {chipState === 1 && <div className="block-check"></div>}
                  </div>
                  <img src={redChip} alt="#" />
                  <span className={`${chipState === 1 ? "bold-text" : ""}`}>
                    Красные
                  </span>
                </div>
                <div className="gold-chip" onClick={() => changeChip(0)}>
                  <div className="border-block">
                    {chipState === 0 && <div className="block-check"></div>}
                  </div>
                  <img src={yellow_bg} alt="#" />
                  <span className={`${chipState === 0 ? "bold-text" : ""}`}>
                    Золотые
                  </span>
                </div>
                <div className="gold-chip" onClick={() => changeChip(2)}>
                  <div className="border-block">
                    {chipState === 2 && <div className="block-check"></div>}
                  </div>
                  <img src={yellow_bg} alt="#" />
                  <span className={`${chipState === 2 ? "bold-text" : ""}`}>
                    VIP
                  </span>
                </div>
              </div>
              {/* <div className="you-money-user">
                <span>СУММА:</span>
              </div> */}
            </div>
            <div className="you-money-right">
              <img src={sale} alt="#" />
            </div>
          </div>

          <div className="you-money-input">
            <div className="you-money-id">
              <div className="input-block">
                {chipState === 2 ? (
                  <>
                    {" "}
                    <div className="input-vip" onClick={openVipSelect}>
                      {vipItem ? (
                        <span>
                          {vipItem.name} {vipItem.price} РУБ.
                        </span>
                      ) : (
                        <span>Выбери пакет</span>
                      )}

                      <img src={vip_pack} alt="#" />
                    </div>
                    {openVip && (
                      <div className="vip-select-block">
                        <ul>
                          {configStateVip?.map((item) => (
                            <li
                              key={item._id}
                              onClick={() => setVipIdData(item)}
                            >
                              <div className="vip-border-block">
                                {vipCheck === item.vipId && (
                                  <div className="vip-center-block"></div>
                                )}
                              </div>
                              <span>{item.name}</span> <p>{item.price} РУБ.</p>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </>
                ) : (
                  <input
                    type="text"
                    onChange={(e) => handleChangeSum(e.target.value)}
                    name="sum"
                    value={stateSum}
                    maxLength={9}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                )}

                {/* <span>rub</span> */}
              </div>
            </div>
            {chipState !== 2 && (
              <div className="red-chip-block">
                {sumState && <span>=</span>}

                <span>{sumState}</span>
                {sumState && chipState === 1 && <img src={redChip} alt="#" />}
                {sumState && chipState === 0 && <img src={yellow_bg} alt="#" />}
              </div>
            )}
          </div>
          {chipState !== 2 && (
            <>
              {chipState === 1 ? (
                <span className="you-money-text">
                  250 руб. = {Math.ceil(getChipsCount(250, "red"))} фишек.
                  Минимальная сумма {configState?.minAmount} руб.
                </span>
              ) : (
                <span className="you-money-text">
                  230 руб. = {Math.ceil(getChipsCount(230, "yellow"))} фишек.
                  Минимальная сумма {configState?.minAmountYellow} руб.
                </span>
              )}
            </>
          )}

          <div className="goSecondPage" onClick={buyChip}>
            ДАЛЕЕ
          </div>

          <form
            name="TinkoffPayForm"
            onSubmit={() => buyChip}
            ref={formRef}
            style={{ display: "none" }}
          >
            <input
              className="tinkoffPayRow"
              type="hidden"
              name="terminalkey"
              value="1647250267012"
            />
            <input
              className="tinkoffPayRow"
              type="hidden"
              name="frame"
              value="true"
            />
            <input
              className="tinkoffPayRow"
              type="hidden"
              name="language"
              value="ru"
            />
            <input className="tinkoffPayRow" type="hidden" name="email" />
            <input className="tinkoffPayRow" type="hidden" name="phone" />
            <input
              className="tinkoffPayRow"
              type="text"
              placeholder="Сумма заказа"
              name="amount"
              required
            />
            <input
              className="tinkoffPayRow"
              type="text"
              placeholder="Номер заказа"
              name="order"
            />
            <input className="tinkoffPayRow" type="hidden" name="receipt" />
            {/*<input className="tinkoffPayRow" type="submit" value="Оплатить"/>*/}
          </form>
        </div>
      );
    }
  };

  return (
    <>
      <MetaTags>
        <title>Оплата через YoоMoney в Blot Club Ru</title>
        <meta
          id="meta-description"
          name="description"
          content="Покупайте фишки в Blot Club и оплачивайте через Юкассу. Приобретение фишек Blot Club в России, через банковские карты mastercard, Visa, МИР, SberPay, а также через QIWI кошелек и YooMoney. Покупайте и получайте бонус от Blot Club в размере 30% от купленных фишек."
        />
        <meta
          id="og-title"
          property="og:title"
          content="Оплата через YoоMoney в Blot Club Ru"
        />
        <meta id="og-image" property="og:image" content="path/to/image.jpg" />
      </MetaTags>
      {statePopup && (
        <div className="popup" onClick={openPopup}>
          <div className="popup-info">
            <img src={userInfo} alt="#" />
            <div className="goSecondPage" onClick={openPopup}>
              ЗАКРЫТЬ
            </div>
          </div>
        </div>
      )}

      {renderByStep()}
    </>
  );
};
export default Tinkoff;
