import React from "react";
import { useNavigate } from "react-router-dom";
import "./header-scroll.scss";
import bazarLogo from "../../../assets/bazarlogo.svg";
import buyChipScroll from "../../../assets/buy-chips-scroll.webp";

const HeaderScroll = ({ scrollRef }) => {
  const navigate = useNavigate();

  const goPayments = () => {
    navigate("/payments");
  };
  const goMain = () => {
    navigate("/");
  };
  return (
    <header className="app-header-privacy" ref={scrollRef}>
      <div className="container">
        <div className="header-block-scroll">
          <img
            src={bazarLogo}
            alt="#"
            className={"logo-main"}
            onClick={goMain}
          />

          <img
            src={buyChipScroll}
            alt="#"
            className={"buy-chip"}
            onClick={goPayments}
          />
        </div>
      </div>
    </header>
  );
};

export default HeaderScroll;
