import React, { useState, useEffect } from "react";
import "../you-money-user/you-money-user.scss";
import { useLocation, useNavigate } from "react-router-dom";
import angle from "../../assets/angle.png";
import TelCellLogo from "../../assets/telcell-horizontal.png";
import userInfo from "../../assets/userinfo.png";
import question from "../../assets/question.png";
import blockImg from "../../assets/id-money.png";
import { checkUser_req } from "../../api/payment.api";
import { MetaTags } from "react-meta-tags";

function isValidEmail(email) {
  if (!email) {
    return false;
  }
  email = String(email);

  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !!email.match(regex);
}

function isValidPhone(phone) {
  if (!phone) {
    return false;
  }

  phone = String(phone);

  return !!phone.match(/[0-9]{8,}/g);
}

const TelCellUser = ({ openPopup, statePopup }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [state, setState] = useState({
    userId: window.localStorage.getItem("fill_userId") || "",
  });
  const [userError, setUserError] = useState(false);
  const [emailPhoneError, setEmailPhoneError] = useState(false);

  const [checkError, setCheckError] = useState(false);
  const [open, setOpen] = useState(false);
  const [emailPhone, setEmailPhone] = useState(
    window.localStorage.getItem("fill_emailPhone") || ""
  );
  const combo = location?.state?.id;

  const goFirstPage = () => {
    if (combo) {
      navigate("/combo");
    } else {
      navigate("/payments");
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const blockOpen = () => {
    setOpen(!open);
  };
  useEffect(() => {
    setTimeout(() => {
      setOpen(false);
    }, 5000);
  }, []);
  const handleChange = (evt) => {
    const value = evt?.target?.value;

    setState({
      ...state,
      [evt?.target?.name]: value,
    });
  };
  const handleEmailPhoneChange = (evt) => {
    const value = evt?.target?.value;
    setEmailPhone(value);
  };
  const checkUser = async () => {
    try {
      let _emailPhone = String(emailPhone);

      if (_emailPhone && _emailPhone[0] === "+") {
        _emailPhone = _emailPhone.slice(1);
      }

      if (
        !_emailPhone ||
        (!isValidEmail(_emailPhone) && !isValidPhone(_emailPhone))
      ) {
        return setEmailPhoneError(true);
      }

      setUserError(false);
      setEmailPhoneError(false);

      const checkUserData = await checkUser_req(state, _emailPhone);

      window.localStorage.setItem("fill_userId", state.userId);
      window.localStorage.setItem("fill_emailPhone", _emailPhone);

      if (checkUserData?.data?.userId) {
        navigate("/tel-cell", {
          state: { user: checkUserData.data, id: combo ? combo : null },
        });
      }
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.data &&
        e.response.data.data.errorCode === 1
      ) {
        if (e.response.data.data.emailPhone === false) {
          return setEmailPhoneError(true);
        } else if (e.response.data.data.userId === false) {
          return setUserError(true);
        }
      }
      setCheckError(true);
    }
  };
  const TelCellContent = () => {
    return (
      <div className="you-money">
        <div
          className="prev-case"
          onClick={goFirstPage}
        >
          <img
            src={angle}
            alt="#"
          />
          <span>назад</span>
        </div>
        <div className="you-money-img-block">
          <img
            src={TelCellLogo}
            alt="#"
          />
        </div>
        <div className="you-money-id">
          <span>ВВЕДИТЕ СВОЙ ID</span>
          <div className="input-block">
            <input
              type="text"
              onChange={handleChange}
              name="userId"
              value={state.userId}
              placeholder="USER ID"
              maxLength={9}
              className={`input ${userError ? "input-error" : ""}`}
            />
            <img
              src={question}
              alt="#"
              onClick={openPopup}
            />
          </div>
          <p onClick={blockOpen}>Что такое ID?</p>
          {open && (
            <div className="block-question">
              <img
                src={blockImg}
                alt="#"
              />
              <span>
                Нажав на Ваше фото в игре можете увидеть Ваш ID номер в игре
              </span>
            </div>
          )}
          <br />
          <div className="input-block">
            <input
              type="text"
              onChange={handleEmailPhoneChange}
              name="phoneEmail"
              value={emailPhone}
              placeholder="Телефон или email"
              className={`input ${emailPhoneError ? "input-error" : ""}`}
            />
          </div>
          <span className="phone-suggest">Пример: +7XXXXXXXXXX</span>

          {userError && (
            <span className="user-error">Данного ID не существует</span>
          )}
          {emailPhoneError && (
            <span className="user-error">
              Неверный формат телефона или email
            </span>
          )}
          {checkError && (
            <span className="user-error">Что то пошло не так</span>
          )}
        </div>
        <div
          className="goSecondPage"
          onClick={checkUser}
        >
          ДАЛЕЕ
        </div>
      </div>
    );
  };
  return (
    <>
      <MetaTags>
        <title>Оплата через YoоMoney в Blot Club Ru</title>
        <meta
          id="meta-description"
          name="description"
          content="Покупайте фишки в Blot Club и оплачивайте через Юкассу. Приобретение фишек Blot Club в России, через банковские карты mastercard, Visa, МИР, SberPay, а также через QIWI кошелек и YooMoney. Покупайте и получайте бонус от Blot Club в размере 30% от купленных фишек."
        />
        <meta
          id="og-title"
          property="og:title"
          content="Оплата через YoоMoney в Blot Club Ru"
        />
        <meta
          id="og-image"
          property="og:image"
          content="path/to/image.jpg"
        />
      </MetaTags>
      {statePopup && (
        <div
          className="popup"
          onClick={openPopup}
        >
          <div className="popup-info">
            <img
              src={userInfo}
              alt="#"
            />
            <div
              className="goSecondPage"
              onClick={openPopup}
            >
              ЗАКРЫТЬ
            </div>
          </div>
        </div>
      )}
      {TelCellContent()}
    </>
  );
};
export default TelCellUser;
