import "./app.scss";
import { createRef, useEffect, useRef, useState } from "react";
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

import "react-responsive-carousel/lib/styles/carousel.min.css";

import Main from "./pages/main/main";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import Payments from "./pages/payments/payments";
import PrivacyPolicy from "./pages/privacy-policy/privacy-policy";
import HeaderPrivacy from "./components/header/header-privacy/header-privacy";
import YouMoney from "./pages/you-money/you-money";

import Success from "./pages/success/success";
import HeaderScroll from "./components/header/header-scroll/header-scroll";
import ErrorPage from "./pages/error-page/error-page";
import Tinkoff from "./pages/tinkoff/tinkoff";
import Idram from "./pages/idram/idram";
import PaymentError from "./pages/payment-error/payment-error";
import TellCell from "./pages/tell-cell/tell-cell";
import TelCellSuccess from "./pages/tel-cell-success/tel-cell-success";
import Combo from "./pages/combo/combo";
import YouMoneyUser from "./pages/you-money-user/you-money-user";
import IdramUser from "./pages/idram-user/idram-user";
import TinkOffUser from "./pages/tinkoff-user/tinkoff-user";
import TelCellUser from "./pages/tel-cell-user/tel-cell-user";

import Tourlist from "./pages/tour-list/tour-list";
import UserList from "./pages/user-list/user-list";
import TournamentUser from "./pages/tournament-user/tournament-user";
import EasyWallet from "./pages/easy-wallet/easy-wallet";
import EasyWalletPayment from "./pages/easy-wallet-payment/easy-wallet-payment";
import EasyWalletSuccess from "./pages/easy-wallet-success/easy-wallet-success";
import PaymentPage from "./pages/payment-page/payment-page";
import PaymentMoney from "./pages/payment-money/payment-money";
import PaymentPageXsollai from "./pages/payment-page-xsollai/payment-page-xsollai";
import PaymentSuccess from "./pages/payment-success/payment-success";
import closeIcon from "./assets/X.png";

const App = () => {
  const [openBurger, setOpenBurger] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const anchorRef = useRef(null);

  const scrollRef = createRef();
  const [scrollHeader, setScrollHeader] = useState(false);
  const locationSlice = location.pathname.slice(1);

  const easyPayData = locationSlice.slice(0, 15);
  const tourSlice = location.pathname.slice(1, 10);
  const tournamentSlice = location.pathname.slice(1, 16);
  const locationPrivacy = locationSlice === "privacy-policy" ? true : false;
  const locationPathName = location.pathname === "/" ? true : false;
  const tourList = tourSlice === "tour-list";

  const paymentsReq = locationSlice === "payments";
  const paymentMoney = locationSlice === "payment-money";
  const paymentsReqData = locationSlice === "yoo-money";
  const youMoneyUser = locationSlice === "yoo-money-user";
  const easyWallet = locationSlice === "easy-wallet";
  const esayWalletPayment = locationSlice === "easy-wallet-payment";
  const paymentSuccess = locationSlice === "payment/success";
  const tinkofUser = locationSlice === "tinkoff-user";
  const paymentPage = locationSlice === "payment-page";
  const tinkof = locationSlice === "tinkoff";
  const telCellUser = locationSlice === "tel-cell-user";
  const telCell = locationSlice === "tel-cell";
  const idramUser = locationSlice === "idram-user";
  const userList = locationSlice === "user-list";
  const idram = locationSlice === "idram";
  const comboSlice = locationSlice === "combo";
  const allTournamentUser = tournamentSlice === "tournament-user";
  const xsollai = locationSlice === "payment-page-xsollai";
  const [statePopup, setStatePopup] = useState(false);
  const openPopup = () => {
    setStatePopup(!statePopup);
  };
  const changeBurger = () => {
    setOpenBurger(!openBurger);
  };
  window.addEventListener("scroll", function (e) {
    const scroollData = window.scrollY;
    if (scroollData > scrollRef?.current?.offsetHeight - 20) {
      setScrollHeader(true);
    } else if (scroollData < scrollRef?.current?.offsetHeight + 20) {
      setScrollHeader(false);
    }
  });

  const headerCheck = () => {
    if (locationPathName) {
      if (scrollHeader) {
        return <HeaderScroll scrollRef={scrollRef} />;
      } else {
        return (
          <Header
            scrollRef={scrollRef}
            scrollHeader={scrollHeader}
            openBurger={openBurger}
            setOpenBurger={setOpenBurger}
            changeBurger={changeBurger}
          />
        );
      }
    } else {
      if (locationPrivacy) {
        return <HeaderPrivacy />;
      } else {
        return (
          <Header
            scrollRef={scrollRef}
            scrollHeader={scrollHeader}
            openBurger={openBurger}
            setOpenBurger={setOpenBurger}
            changeBurger={changeBurger}
          />
        );
      }
    }
  };

  const youKasa = "https://yookassa.ru/checkout-widget/v1/checkout-widget.js";
  const loadScript = async (src) => {
    return new Promise(function (resolve, reject) {
      var s;
      s = document.createElement("script");
      s.src = src;
      s.onload = resolve;
      s.onerror = reject;
      document.head.appendChild(s);
    });
  };

  useEffect(() => {
    loadScript(youKasa);
    if (location?.hash === "#apk") {
      window.scrollTo({ top: anchorRef.current.offsetTop, behavior: "smooth" });
    }
  }, [location]);
  const goTourPage = () => {
    setOpenBurger(false);
    navigate("/tour-list/1");
  };
  const goPayments = () => {
    setOpenBurger(false);
    navigate("/payments");
  };
  return (
    <div className={`${openBurger ? "app-hidden" : ""} app`}>
      {openBurger && (
        <div className="open-burger">
          <button onClick={changeBurger} className={"close-block"}>
            <img src={closeIcon} alt="#" className="close-img" />
          </button>
          <div onClick={goTourPage}>
            <span>ИСТОРИЯ ТУРНИРОВ</span>
          </div>
          <div onClick={goPayments}>
            <span>КУПИТЬ ФИШКИ</span>
          </div>
        </div>
      )}

      {!paymentsReq &&
        !paymentsReqData &&
        !paymentMoney &&
        !paymentPage &&
        !comboSlice &&
        !youMoneyUser &&
        !tinkofUser &&
        !tinkof &&
        !telCellUser &&
        !telCell &&
        !idramUser &&
        !idram &&
        !tourList &&
        !userList &&
        !easyWallet &&
        !paymentSuccess &&
        !esayWalletPayment &&
        !xsollai &&
        !allTournamentUser &&
        headerCheck()}

      {locationSlice === "" ||
      locationSlice === "payments" ||
      locationSlice === "privacy-policy" ||
      locationSlice === "payment-page" ||
      locationSlice === "yoo-money" ||
      locationSlice === "payment-money" ||
      locationSlice === "yoo-money-user" ||
      locationSlice === "payment-details" ||
      locationSlice === "tinkoff" ||
      locationSlice === "idram" ||
      locationSlice === "tel-cell" ||
      locationSlice === "success" ||
      locationSlice === "payment/success" ||
      locationSlice === "tel-cell-success" ||
      locationSlice === "idram-user" ||
      easyPayData === "easypay/success" ||
      tourSlice === "tour-list" ||
      tournamentSlice === "tournament-user" ||
      locationSlice === "combo" ||
      locationSlice === "tinkoff-user" ||
      locationSlice === "tel-cell-user" ||
      locationSlice === "easy-wallet" ||
      locationSlice === "easy-wallet-payment" ||
      locationSlice === "payment-page-xsollai" ||
      locationSlice === "tournament" ||
      locationSlice === "user-list" ||
      locationSlice === "payment-error" ? (
        <Routes>
          <Route exact path="/" element={<Main />} />
          <Route path="/payments" element={<Payments />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path={`/tour-list/:id`} element={<Tourlist />} />
          <Route
            path="/yoo-money-user"
            element={
              <YouMoneyUser openPopup={openPopup} statePopup={statePopup} />
            }
          />
          <Route
            path="yoo-money"
            element={<YouMoney openPopup={openPopup} statePopup={statePopup} />}
          />
          <Route
            path="payment-money"
            element={
              <PaymentMoney openPopup={openPopup} statePopup={statePopup} />
            }
          />
          <Route
            path="tinkoff"
            element={<Tinkoff openPopup={openPopup} statePopup={statePopup} />}
          />
          <Route
            path="tinkoff-user"
            element={
              <TinkOffUser openPopup={openPopup} statePopup={statePopup} />
            }
          />
          <Route path="user-list" element={<UserList />} />
          <Route path={`/tournament-user/:id`} element={<TournamentUser />} />
          <Route
            path="idram-user"
            element={
              <IdramUser openPopup={openPopup} statePopup={statePopup} />
            }
          />
          <Route
            path="easy-wallet"
            element={
              <EasyWallet openPopup={openPopup} statePopup={statePopup} />
            }
          />
          <Route
            path="easy-wallet-payment"
            element={
              <EasyWalletPayment
                openPopup={openPopup}
                statePopup={statePopup}
              />
            }
          />

          <Route
            path="idram"
            element={<Idram openPopup={openPopup} statePopup={statePopup} />}
          />
          <Route
            path="tel-cell"
            element={<TellCell openPopup={openPopup} statePopup={statePopup} />}
          />
          <Route
            path="tel-cell-user"
            element={
              <TelCellUser openPopup={openPopup} statePopup={statePopup} />
            }
          />
          <Route path="success" element={<Success />} />
          <Route path="payment/success" element={<PaymentSuccess />} />
          {/* <Route path="tournament" element={<Tournament />} /> */}
          <Route path="tel-cell-success" element={<TelCellSuccess />} />
          <Route
            path="/easypay/success/:id"
            exact
            element={<EasyWalletSuccess />}
          />
          <Route
            path="payment-error"
            element={
              <PaymentError openPopup={openPopup} statePopup={statePopup} />
            }
          />
          <Route path="/combo" element={<Combo />} />
          <Route path="/payment-page" element={<PaymentPage />} />
          <Route
            path="/payment-page-xsollai"
            element={<PaymentPageXsollai />}
          />
        </Routes>
      ) : (
        <ErrorPage />
      )}

      <div className={`${statePopup ? "footer-layer" : ""}`} ref={anchorRef}>
        <Footer state={statePopup} />
      </div>
    </div>
  );
};

export default App;
