import React from "react";
import telCellImg from "../../assets/telcell.png";
import SmileGoodimg from "../../assets/smile-good.png";

import "./tel-cell-success.scss";

const TelCellSuccess = () => {
  return (
    <div className="success">
      <div className="success-you-money">
        <img src={telCellImg} alt="#" />
      </div>
      <div className="success-content">
        <img src={SmileGoodimg} alt="#" />
        <span className="success-shop">СПАСИБО ЗА ПОКУПКУ!!</span>
      </div>
    </div>
  );
};

export default TelCellSuccess;
