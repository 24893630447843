import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./payment-money.scss";
import youMoneyPay from "../../assets/you-money-pay.png";
import redChip from "../../assets/red-chip.png";
import sale from "../../assets/sale.png";
import userInfo from "../../assets/userinfo.png";
import angle from "../../assets/angle.png";
import redCombo from "../../assets/red-chip-combo.png";
import yellowCombo from "../../assets/yellow-combo.png";
import vipCombo from "../../assets/vip-combo.png";
import { paymentConfig_req, vipConfig_req } from "../../api/payment.api";
import axios from "axios";
import smile from "../../assets/smile.png";
import { formatNumberWithSpaces } from "../../utils/formatter.util";
import { MetaTags } from "react-meta-tags";
import yellow_bg from "../../assets/gold.png";
import vip_pack from "../../assets/vip-pack.png";

const PaymentMoney = ({ openPopup, statePopup }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [disable, setDisable] = useState(true);
  const [state, setState] = useState({
    userId: window.localStorage.getItem("fill_userId") || "",
  });
  const [stateSum, setStateSum] = useState("");
  const [configState, setConfigState] = useState(null);
  const [configVip, setConfigVip] = useState(null);

  const [sumState, setSumState] = useState("");

  const [confirmationToken, setConfirmationToken] = useState("");

  const [vipItem, setVipItem] = useState(null);

  const [errorPayment, setErrorPayment] = useState(false);
  const [chipState, setChipState] = useState(1);
  const [successData, setSuccessData] = useState(false);
  const [createData, setCreateData] = useState(null);
  const [vipCheck, setVipCheck] = useState(0);
  const [openVip, setOpenVip] = useState(false);

  const emailPhone = window.localStorage.getItem("fill_emailPhone") || "";
  const userId = location?.state?.user?.userId;
  const username = location?.state?.user?.username;
  const comboText = [
    { text: "100 000", img: redCombo },
    { text: "1000", img: yellowCombo },
    { text: "1 МЕСЯЦ VIP", img: vipCombo },
  ];

  const comboData = location?.state?.id;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const goFirstPage = () => {
    navigate("/yoo-money-user", {
      state: { id: comboData ? comboData : null },
    });
  };

  const openVipSelect = () => {
    setOpenVip(!openVip);
  };

  const buyChip = async () => {
    setDisable(false);

    const chipData = () => {
      if (chipState === 0 && comboData !== "combo") {
        return 1;
      } else if (chipState === 1 && comboData !== "combo") {
        return 0;
      } else if (comboData === "combo") {
        return 4;
      }
    };
    if (disable) {
      try {
        const response = await axios.post(
          "https://jupiter.blotclub.am/api/payment/yoo/create",
          {
            userId,
            amount: comboData === "combo" ? "1" : 1,
            emailPhone,
            paymentTypeId: 0,
            vipId: vipCheck ? vipCheck : null,
            chipType: chipData(),
            specialPackId: comboData === "combo" ? 100 : undefined,
          }
        );

        setCreateData(response.data);
        if (response.data.confirmation) {
          setDisable(true);
          if (response.data.confirmation.confirmation_token) {
            setConfirmationToken(response.data.confirmation.confirmation_token);
          } else if (response.data.confirmation.confirmation_url) {
            window.location.href = response.data.confirmation.confirmation_url;
          }
        }
      } catch (e) {
        setDisable(true);
        console.error("buyChip Error -> ", e);
      }
    }
  };

  useEffect(() => {
    if (configState && stateSum) {
      handleChangeSum(stateSum);
    }
  }, [chipState]);
  const changeChip = (index) => {
    setChipState(index);
  };

  const getChipsCount = (value, chipType) => {
    let multiplier = 5;

    if (configState) {
      multiplier =
        chipType === "red"
          ? configState.defaultPrice
          : configState.defaultPriceYellow;

      if (configState.ranges && configState.ranges.length) {
        const neededRange =
          chipType === "red"
            ? configState.ranges
                .filter((rangeData) => value >= rangeData.max)
                .sort((a, b) => b.max - a.max)[0]
            : configState.ranges
                .filter((rangeData) => value >= rangeData.maxYellow)
                .sort((a, b) => b.maxYellow - a.maxYellow)[0];

        if (neededRange) {
          multiplier =
            chipType === "red" ? neededRange.price : neededRange.priceYellow;
        }
      }
    }

    let chipsCount = multiplier * value;
    if (chipState === 1) {
      chipsCount += (chipsCount * configState?.percent) / 100;
    } else {
      chipsCount += (chipsCount * configState?.percentYellow) / 100;
    }

    return chipsCount;
  };

  const handleChangeSum = (value) => {
    let multiplier = 5;

    if (configState) {
      multiplier =
        chipState === 1
          ? configState.defaultPrice
          : configState.defaultPriceYellow;

      if (configState.ranges && configState.ranges.length) {
        const neededRange =
          chipState === 1
            ? configState.ranges
                .filter((rangeData) => value >= rangeData.max)
                .sort((a, b) => b.max - a.max)[0]
            : configState.ranges
                .filter((rangeData) => value >= rangeData.maxYellow)
                .sort((a, b) => b.maxYellow - a.maxYellow)[0];

        if (neededRange) {
          multiplier =
            chipState === 1 ? neededRange.price : neededRange.priceYellow;
        }
      }
    }

    let chipsCount = multiplier * value;
    if (chipState === 1) {
      chipsCount += (chipsCount * configState?.percent) / 100;
    } else {
      chipsCount += (chipsCount * configState?.percentYellow) / 100;
    }

    setSumState(Math.ceil(chipsCount));

    setStateSum(value);
  };
  const configVipReq = async () => {
    const vipData = await vipConfig_req();
    setConfigVip(vipData);
  };
  useEffect(() => {
    if (!confirmationToken) {
      const configDataReq = async () => {
        const configData = await paymentConfig_req();

        setConfigState(configData);
      };
      configDataReq();
    }
    configVipReq();
  }, []);
  const setVipIdData = (item) => {
    setOpenVip(!openVip);
    setVipItem(item);
    setVipCheck(item.vipId);
  };
  const configStateVip = configVip ? Object.values(configVip) : null;

  useEffect(() => {
    if (confirmationToken) {
      setTimeout(() => {
        const checkout = new window.YooMoneyCheckoutWidget({
          confirmation_token: confirmationToken,
          error_callback: function (error) {},
        });

        checkout.on("complete", async (completeData) => {
          //Код, который нужно выполнить после оплаты.
          if (completeData && completeData.status === "success") {
            setSuccessData(true);
          } else {
            setErrorPayment(true);
          }

          //Удаление инициализированного виджета
          checkout.destroy();
        });

        //Отображение платежной формы в контейнере
        checkout.render("payment-form");
      }, 1000);
    }
  }, [confirmationToken]);

  const goPayments = () => {
    navigate("/");
  };

  const renderByStep = () => {
    if ((createData && successData) || errorPayment) {
      return (
        <div className="success">
          <div className="success-you-money">
            <img src={youMoneyPay} alt="#" />
          </div>

          <div className="success-content">
            {createData ? (
              <span className="success-shop">СПАСИБО ЗА ПОКУПКУ!!</span>
            ) : (
              <img src={smile} alt="#" />
            )}
            {createData && successData ? (
              <div className="sucess-content-payment">
                <div className="success-item">
                  <span>На учетную запись ID:</span>
                  <p>{createData?.userId}</p>
                </div>

                <div className="success-item">
                  {createData?.reward?.vip && !createData?.reward?.special && (
                    <span>Вы получили VIP "{createData.reward.name}"</span>
                  )}
                  {!createData?.reward?.vip && !createData?.reward?.special && (
                    <>
                      <span>зачислено фишек:</span>
                      <p>
                        {formatNumberWithSpaces(
                          Math.ceil(
                            createData?.reward?.redChips
                              ? createData.reward.redChips
                              : createData.reward.yellowChips
                          )
                        )}
                      </p>
                    </>
                  )}
                  {createData?.reward?.special && (
                    <span>Вы получили "МЕГА Комбо" </span>
                  )}
                </div>
                <div className="success-item">
                  <span>Заказ №:</span>
                  <p>{createData?.receiptId}</p>
                </div>
              </div>
            ) : (
              <>
                {/* {" "}
                {error?.data?.errorCode === 4 && (
                  <span>К сожалению процесс оплаты был прерван </span>
                )} */}
                {errorPayment && <span>Ой, что-то пошло не так.</span>}
                {errorPayment && (
                  <p style={{ textAlign: "center" }}>
                    Пожалуйста напишите в службу поддержки
                    <br />
                    <br />
                    <a
                      href="mailto:support@blotclub.am"
                      style={{ color: "#9f1313", fontWeight: "bold" }}
                    >
                      support@blotclub.am
                    </a>
                  </p>
                )}
                {errorPayment && (
                  <p>
                    Номер транзакции: <span>{confirmationToken}</span>
                  </p>
                )}{" "}
              </>
            )}
          </div>
          {/* {error?.data?.errorCode === 4 && (
            <div className="goSecondPage" onClick={goPaymentsData}>
              Попробавать снова
            </div>
          )} */}
          {(errorPayment || state) && (
            <div className="goSecondPage cancel" onClick={goPayments}>
              НАЗАД
            </div>
          )}
        </div>
      );
    }

    if (confirmationToken) {
      return (
        <div className="finish-case">
          <div className="prev-case" onClick={() => goFirstPage()}>
            <img src={angle} alt="#" />
            <span>назад</span>
          </div>
          <div id="payment-form" />
        </div>
      );
    }
  };

  return (
    <>
      <MetaTags>
        <title>Оплата через YoоMoney в Blot Club Ru</title>
        <meta
          id="meta-description"
          name="description"
          content="Покупайте фишки в Blot Club и оплачивайте через Юкассу. Приобретение фишек Blot Club в России, через банковские карты mastercard, Visa, МИР, SberPay, а также через QIWI кошелек и YooMoney. Покупайте и получайте бонус от Blot Club в размере 30% от купленных фишек."
        />
        <meta
          id="og-title"
          property="og:title"
          content="Оплата через YoоMoney в Blot Club Ru"
        />
        <meta id="og-image" property="og:image" content="path/to/image.jpg" />
      </MetaTags>
      {statePopup && (
        <div className="popup" onClick={openPopup}>
          <div className="popup-info">
            <img src={userInfo} alt="#" />
            <div className="goSecondPage" onClick={openPopup}>
              ЗАКРЫТЬ
            </div>
          </div>
        </div>
      )}

      {renderByStep()}
    </>
  );
};
export default PaymentMoney;
