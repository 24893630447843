import React, { useEffect, useState } from "react";
import userInfo from "../../assets/userinfo.png";
import redChip from "../../assets/red-chip.png";
import TelCellLogo from "../../assets/telcell-horizontal.png";
import angle from "../../assets/angle.png";
import yellow_bg from "../../assets/gold.png";
import vip_pack from "../../assets/vip-pack.png";
import redCombo from "../../assets/red-chip-combo.png";
import yellowCombo from "../../assets/yellow-combo.png";
import vipCombo from "../../assets/vip-combo.png";
import sale50 from "../../assets/sale-50.png";
import { MetaTags } from "react-meta-tags";
import { useLocation, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import {
  getTellCellData_req,
  paymentTelCellConfig_req,
  vipConfigTelCell_req,
} from "../../api/payment.api";
import "./tell-cell.scss";

const TellCell = ({ openPopup, statePopup }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [stateSum, setStateSum] = useState("");
  const [chipState, setChipState] = useState(1);
  const [openVip, setOpenVip] = useState(false);
  const [configVip, setConfigVip] = useState(null);
  const [vipItem, setVipItem] = useState(null);
  const [sumState, setSumState] = useState("");
  const [configState, setConfigState] = useState(null);
  const [vipCheck, setVipCheck] = useState(0);

  const userId = location?.state?.user?.userId;
  const username = location?.state?.user?.username;
  const comboText = [
    { text: "100 000", img: redCombo },
    { text: "1000", img: yellowCombo },
    { text: "1 МЕСЯЦ VIP", img: vipCombo },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const comboData = location?.state?.id;
  const goFirstPage = () => {
    navigate("/tel-cell-user", {
      state: { id: comboData ? comboData : null },
    });
  };
  const openVipSelect = () => {
    setOpenVip(!openVip);
  };

  // const goFirstPage = () => {
  //   setState({
  //     userId: "",
  //   });
  //   setEmailPhone({
  //     emailPhone: "",
  //   });
  //   navigate("/payments");
  // };

  const configDataReq = async () => {
    const configData = await paymentTelCellConfig_req();

    setConfigState(configData);
  };
  useEffect(() => {
    if (configState && stateSum) {
      handleChangeSum(stateSum);
    }
  }, [chipState]);

  useEffect(() => {
    configDataReq();

    configVipReq();
  }, []);

  const changeChip = (index) => {
    // setError(false);
    setChipState(index);
  };

  const setVipIdData = (item) => {
    setOpenVip(!openVip);
    setVipItem(item);
    setVipCheck(item.vipId);
  };
  const configStateVip = configVip ? Object.values(configVip) : null;

  const configVipReq = async () => {
    const vipData = await vipConfigTelCell_req();
    setConfigVip(vipData);
  };
  const handleChangeSum = (value) => {
    const chipsCount = getChipsCount(value, chipState === 1 ? "red" : "yellow");
    setSumState(Math.ceil(chipsCount));

    setStateSum(value);
  };

  const buyChip = async () => {
    try {
      let chipType = chipState === 0 ? 1 : 0;
      if (vipCheck) {
        chipType = 3;
      }
      if (comboData === "combo") {
        chipType = 4;
      }

      const response = await getTellCellData_req(
        isMobile ? "mobile" : "web",
        userId,
        vipItem ? vipItem.price : stateSum,
        chipType,
        vipCheck,
        comboData
      );

      if (response.data) {
        // setError(false);
        const pay = response.data;
        window.location.href = `https://telcellmoney.am/invoices?issuer=${pay.issuer}&action=${pay.action}&currency=${pay.currency}&price=${pay.price}&issuer_id=${pay.issuer_id}&product=${pay.product}&valid_days=${pay.valid_days}&lang=am&security_code=${pay.security_code}`;
        // window.open(
        //   `https://telcellmoney.am/invoices?issuer=${pay.issuer}&action=${pay.action}&currency=${pay.currency}&price=${pay.price}&issuer_id=${pay.issuer_id}&product=${pay.product}&valid_days=${pay.valid_days}&lang=am&security_code=${pay.security_code}`,
        //   "_blank"
        // );
      }

      // if (response?.data?.paymentUrl) {
      //   window.open(response.data.paymentUrl, "_blank");
      // }
    } catch (e) {
      console.error("buyChip Error -> ", e);
    }
  };

  const getChipsCount = (value, chipType) => {
    let multiplier = 5;

    if (configState) {
      multiplier =
        chipType === "red"
          ? configState.defaultPrice
          : configState.defaultPriceYellow;

      if (configState.ranges && configState.ranges.length) {
        const neededRange =
          chipType === "red"
            ? configState.ranges
                .filter((rangeData) => value >= rangeData.max)
                .sort((a, b) => b.max - a.max)[0]
            : configState.ranges
                .filter((rangeData) => value >= rangeData.maxYellow)
                .sort((a, b) => b.maxYellow - a.maxYellow)[0];

        if (neededRange) {
          multiplier =
            chipType === "red" ? neededRange.price : neededRange.priceYellow;
        }
      }
    }

    let chipsCount = multiplier * value;
    if (chipState === 1) {
      chipsCount += (chipsCount * configState?.percent) / 100;
    } else {
      chipsCount += (chipsCount * configState?.percentYellow) / 100;
    }

    return chipsCount;
  };

  const renderByStep = () => {
    if (comboData) {
      return (
        <div className="you-money-second">
          <div
            className="prev-case"
            onClick={() => goFirstPage()}
          >
            <img
              src={angle}
              alt="#"
            />
            <span>назад</span>
          </div>
          <div className="you-money-content">
            <div className="you-money-left">
              <div className="you-money-img-block">
                <img
                  src={TelCellLogo}
                  alt="#"
                />
              </div>
              <div className="you-money-user">
                <span>ID:</span>
                <p>#{userId}</p>
              </div>
              <div className="you-money-user">
                <span>ИГРОК:</span>
                <p>{username}</p>
              </div>
            </div>
          </div>
          <div className="you-money-input">
            <div className="you-money-id">
              <div className="input-block input-block-combo">
                <div className="combo-info">
                  <span>35.000 драм</span>
                </div>
              </div>
            </div>
          </div>

          <div className="combo-block">
            {comboText.map((item) => (
              <div className="combo-item">
                <span>{item.text}</span>
                <img
                  src={item.img}
                  alt="#"
                />
              </div>
            ))}
          </div>

          <div
            className={`${comboData === "combo" ? "comboBtn" : "goSecondPage"}`}
            onClick={buyChip}
          >
            ДАЛЕЕ
          </div>
        </div>
      );
    } else {
      return (
        <div className="you-money-second">
          <div
            className="prev-case"
            onClick={() => goFirstPage()}
          >
            <img
              src={angle}
              alt="#"
            />
            <span>назад</span>
          </div>
          <div className="you-money-content">
            <div className="you-money-left">
              <div className="you-money-img-block">
                <img
                  src={TelCellLogo}
                  alt="#"
                />
              </div>
              <div className="you-money-user">
                <span>ID:</span>
                <p>#{userId}</p>
              </div>
              <div className="you-money-user">
                <span>ИГРОК:</span>
                <p>{username}</p>
              </div>
              <div className="chip-block">
                <div
                  className="gold-chip"
                  onClick={() => changeChip(1)}
                >
                  <div className="border-block">
                    {chipState === 1 && <div className="block-check"></div>}
                  </div>
                  <img
                    src={redChip}
                    alt="#"
                  />
                  <span className={`${chipState === 1 ? "bold-text" : ""}`}>
                    Красные
                  </span>
                </div>
                <div
                  className="gold-chip"
                  onClick={() => changeChip(0)}
                >
                  <div className="border-block">
                    {chipState === 0 && <div className="block-check"></div>}
                  </div>
                  <img
                    src={yellow_bg}
                    alt="#"
                  />
                  <span className={`${chipState === 0 ? "bold-text" : ""}`}>
                    Золотые
                  </span>
                </div>
                <div
                  className="gold-chip"
                  onClick={() => changeChip(2)}
                >
                  <div className="border-block">
                    {chipState === 2 && <div className="block-check"></div>}
                  </div>
                  <img
                    src={yellow_bg}
                    alt="#"
                  />
                  <span className={`${chipState === 2 ? "bold-text" : ""}`}>
                    VIP
                  </span>
                </div>
              </div>
            </div>
            <div className="you-money-right">
              <img
                src={sale50}
                alt="#"
              />
            </div>
          </div>

          <div className="you-money-input">
            <div className="you-money-id">
              <div className="input-block">
                {chipState === 2 ? (
                  <>
                    {" "}
                    <div
                      className="input-vip"
                      onClick={openVipSelect}
                    >
                      {vipItem ? (
                        <span>
                          {vipItem.name} {vipItem.price} ДРАМ
                        </span>
                      ) : (
                        <span>Выбери пакет</span>
                      )}

                      <img
                        src={vip_pack}
                        alt="#"
                      />
                    </div>
                    {openVip && (
                      <div className="vip-select-block">
                        <ul>
                          {configStateVip?.map((item) => (
                            <li
                              key={item._id}
                              onClick={() => setVipIdData(item)}
                            >
                              <div className="vip-border-block">
                                {vipCheck === item.vipId && (
                                  <div className="vip-center-block"></div>
                                )}
                              </div>
                              <span>{item.name}</span> <p>{item.price} ДР.</p>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </>
                ) : (
                  <input
                    type="text"
                    onChange={(e) => handleChangeSum(e.target.value)}
                    name="sum"
                    value={stateSum}
                    maxLength={9}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                )}

                {/* <span>rub</span> */}
              </div>
            </div>

            {chipState !== 2 && (
              <div className="red-chip-block">
                {sumState && <span>=</span>}

                <span>{sumState}</span>
                {sumState && chipState === 1 && (
                  <img
                    src={redChip}
                    alt="#"
                  />
                )}
                {sumState && chipState === 0 && (
                  <img
                    src={yellow_bg}
                    alt="#"
                  />
                )}
              </div>
            )}
          </div>

          {chipState !== 2 && (
            <>
              {chipState === 1 ? (
                <span className="you-money-text">
                  250 драм. = {Math.ceil(getChipsCount(250, "red"))} фишек.
                  Минимальная сумма {configState?.minAmount} драм.
                </span>
              ) : (
                <span className="you-money-text">
                  230 драм. = {Math.ceil(getChipsCount(230, "yellow"))} фишек.
                  Минимальная сумма {configState?.minAmountYellow} драм.
                </span>
              )}
            </>
          )}

          <div
            className="goSecondPage"
            onClick={buyChip}
          >
            ДАЛЕЕ
          </div>
        </div>
      );
    }
  };
  return (
    <>
      <MetaTags>
        <title>Оплата через YoоMoney в Blot Club Ru</title>
        <meta
          id="meta-description"
          name="description"
          content="Покупайте фишки в Blot Club и оплачивайте через Юкассу. Приобретение фишек Blot Club в России, через банковские карты mastercard, Visa, МИР, SberPay, а также через QIWI кошелек и YooMoney. Покупайте и получайте бонус от Blot Club в размере 30% от купленных фишек."
        />
        <meta
          id="og-title"
          property="og:title"
          content="Оплата через YoоMoney в Blot Club Ru"
        />
        <meta
          id="og-image"
          property="og:image"
          content="path/to/image.jpg"
        />
      </MetaTags>
      {statePopup && (
        <div
          className="popup"
          onClick={openPopup}
        >
          <div className="popup-info">
            <img
              src={userInfo}
              alt="#"
            />
            <div
              className="goSecondPage"
              onClick={openPopup}
            >
              ЗАКРЫТЬ
            </div>
          </div>
        </div>
      )}

      {renderByStep()}
    </>
  );
};

export default TellCell;
