import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  tournamentId_req,
  tournamentUserId_req,
} from "../../api/tournament.api";
import TournamentHeader from "../../components/tournament-header/tournament-header";
import goldIcon from "../../assets/medal-gold.png";
import goldCooper from "../../assets/medal-cooper.png";
import goldSilver from "../../assets/medal-silver.png";
import angle from "../../assets/angle.png";
import redChip from "../../assets/red-chip.png";
import vip_pack from "../../assets/vip-img.png";
import "./user-list.scss";
import { MetaTags } from "react-meta-tags";

const UserList = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [userTourList, setUserTourList] = useState(null);
  const [state, setState] = useState({
    text: location?.state?.userId ? location.state.userId : "",
  });
  const [error, setError] = useState("");

  const tournamentData = location?.state?.state;
  const id = tournamentData?.tournamentId
    ? tournamentData?.tournamentId
    : tournamentData?._id;
  const handleChange = (evt) => {
    const value = evt?.target?.value;

    setState({
      ...state,
      [evt?.target?.name]: value,
    });
  };
  const getTourId = async () => {
    const response = await tournamentId_req(id);
    if (response?.data?.length === 0) {
      setError("Введенного ID номера нет в турнире");
      setUserTourList(null);
    } else {
      setUserTourList(response.data);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (location?.state?.userId) {
      searchUser();
    } else {
      getTourId();
    }
  }, [location?.state?.userId]);

  const searchUser = async () => {
    if (state.text.length > 0 && state.text.length < 10) {
      const response = await tournamentUserId_req(id, state.text);

      if (response.data?.length === 0) {
        setError("Введенного ID номера нет в турнире");
        setUserTourList(null);
      } else {
        setError(null);
        setUserTourList(response.data);
      }
    }
    if (state.text.length === 0) {
      setError("введите Ваш ID номер игры");
      setUserTourList(null);
    }
    if (state.text.length > 10) {
      setError("Введенного ID номера нет в турнире");
      setUserTourList(null);
    }
  };

  const medalIcon = (item) => {
    if (item === 1) {
      return <img src={goldIcon} alt="#" />;
    }
    if (item === 2) {
      return <img src={goldSilver} alt="#" />;
    }
    if (item === 3) {
      return <img src={goldCooper} alt="#" />;
    }
  };

  const startDateFn = (item) => {
    const date = new Date(item);
    let year = `${date.getFullYear()}`;

    const yearData = year.slice(2);

    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = ("0" + date.getDate()).slice(-2);
    return `${day}.${month}.${yearData}`;
  };
  const goBack = () => {
    navigate("/tour-list/1");
  };
  return (
    <>
      <MetaTags>
        <title>Blot Club Ru - поиск турниров</title>
        <meta
          id="meta-description"
          name="description"
          content="Blot Club Ru - поиск турниров"
        />
        <meta
          id="og-title"
          property="og:title"
          content="Blot Club Ru - поиск турниров"
        />
        <meta id="og-image" property="og:image" content="path/to/image.jpg" />
      </MetaTags>
      <TournamentHeader />
      <div className="container-back">
        <div className="back" onClick={goBack}>
          <img src={angle} alt="#" />
          <span>назад</span>
        </div>
      </div>

      <div className="container-tournament">
        <div className="tour-list-header">
          <div className="left-header">
            <p>
              {" "}
              {location?.state?.state?.tournamentNames
                ? location?.state?.state?.tournamentNames[1]
                : tournamentData?.name?.[1]}
            </p>
            <span>{startDateFn(tournamentData.startDate)} - </span>
            <span>{startDateFn(tournamentData.endDate)}</span>
          </div>

          <div className="right-header">
            <span className="input-top-text">я в турнире</span>
            <div className="search-user">
              <div className="tour-input">
                <input
                  type={"number"}
                  placeholder="ВАШ ID"
                  onChange={handleChange}
                  value={state.text}
                  name="text"
                  maxLength={10}
                />
              </div>
              <div onClick={searchUser} className="search-btn">
                НАЙТИ
              </div>
            </div>

            {error && <span className="error-text-id">{error}</span>}
          </div>
        </div>
      </div>
      <div className="tournament-user-list">
        <div className="container-tournament">
          <div className="tournament-user-list-table">
            <div className="table-head">
              <div className="th th-place">место</div>
              <div className="th th-player">игрок</div>
              <div className="table-flex">
                <div className="th th-id">ID</div>
                <div className="th th-point">очки</div>
                <div className="th th-reward">награда</div>
              </div>
            </div>
            <div className="table-body">
              {userTourList?.map((item) => (
                <div
                  className={`tr table-flex ${
                    item.isUser ? "active-item" : ""
                  }`}
                >
                  <div className="td td-place">
                    <span className="title">место</span>
                    <div>
                      {item.position} {medalIcon(item.position)}
                    </div>
                  </div>
                  <div className="table-flex table-flex-1">
                    <div className="table-flex">
                      <div className="td td-player">
                        <span className="title">игрок</span>
                        {item.username}
                      </div>
                      <div className="td td-id">
                        <span className="title">ID</span>
                        {item.userId}
                      </div>
                    </div>
                    <div className="table-flex table-flex-2">
                      <div className="td td-point">
                        <span className="title">очки</span>
                        {item.score}
                      </div>
                      <div className="td td-reward">
                        <span className="title">награда</span>
                        <div>
                          {item?.rewards == null && (
                            <div className="red-data chip-data">
                              <span>0</span>
                            </div>
                          )}
                          {item?.rewards?.map((item) => (
                            <>
                              {item.type === "red" && (
                                <div className="red-data chip-data">
                                  <img src={redChip} alt="#" />
                                  <span>{item.value}</span>
                                </div>
                              )}
                              {item.type === "vip" && (
                                <div className="red-data vip-data">
                                  <img src={vip_pack} alt="#" />
                                  <span>VIP</span>
                                </div>
                              )}
                            </>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserList;
